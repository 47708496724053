import { useState } from "react";

import { Alert } from "react-bootstrap";

import * as doctorService from "../../../../services/doctorService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import * as filestack from "filestack-js";

import "./CreateDoctor.css";
import Loader from "../../../Shared/Loader/Loader.js";

const client = filestack.init("AI1Fw5JZR7atkwYVFDSSbz");

export default function CreateDoctor({ handleCreate }) {
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [doctorInfo, setDoctorInfo] = useState({
		firstName: "",
		lastName: "",
		specialty: "",
		description: "",
	});
	const [errors, setErrors] = useState({
		firstName: "",
		lastName: "",
		specialty: "",
		description: "",
	});

	const createDoctorHandler = async (e) => {
		e.preventDefault();

		if (!doctorInfo.firstName || !doctorInfo.lastName || !doctorInfo.specialty || !doctorInfo.description) {
			return notificationService.warning("Всички полета са задължителни!");
		}

		if (!isFilePicked) {
			return notificationService.warning("Не сте избрали снимка!");
		}

		let formData = new FormData();
		formData.append("file", selectedFile.data);

		setIsLoading(true);

		client
			.upload(selectedFile.data)
			.then((data) => {
				doctorService
					.create(
						doctorInfo.firstName,
						doctorInfo.lastName,
						doctorInfo.specialty,
						doctorInfo.description,
						data.url
					)
					.then((doctorData) => {
						handleCreate();
						setDoctorInfo({
							firstName: "",
							lastName: "",
							specialty: "",
							description: "",
						});
						setSelectedFile("");
						notificationService.success("Успешно добавен доктор");
						setIsLoading(false);
					})
					.catch((err) => {
						errorHandlingService.messageHandler(err);
						setIsLoading(false);
					});
			})
			.catch((err) => console.log(err));
	};

	const changeHandler = (e) => {
		const img = {
			preview: URL.createObjectURL(e.target.files[0]),
			data: e.target.files[0],
		};
		setSelectedFile(img);
		setIsFilePicked(true);
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	return (
		<div className="create-doctor-page-wrapper">
			<div className="create-doctor-content-wrapper">
				<div className="create-doctor-form-wrapper">
					<h3>Добави доктор</h3>
					{isLoading ? (
						<Loader />
					) : (
						<form className="create-doctor-form" method="POST" onSubmit={createDoctorHandler}>
							<label htmlFor="firstName">Име</label>
							<input
								className="form-input"
								type="text"
								name="firstName"
								value={doctorInfo.firstName}
								onChange={(e) => {
									setErrors((state) => ({
										...state,
										firstName: "",
									}));
									setDoctorInfo((state) => ({
										...state,
										firstName: e.target.value,
									}));
								}}
								onBlur={onBlurHandler}
							/>
							<Alert className="contact-form-alert" variant="danger" show={errors.firstName.length > 0}>
								{errors.firstName}
							</Alert>
							<label htmlFor="lastName">Фамилия</label>
							<input
								className="form-input"
								type="text"
								name="lastName"
								value={doctorInfo.lastName}
								onChange={(e) => {
									setErrors((state) => ({
										...state,
										lastName: "",
									}));
									setDoctorInfo((state) => ({
										...state,
										lastName: e.target.value,
									}));
								}}
								onBlur={onBlurHandler}
							/>
							<Alert className="contact-form-alert" variant="danger" show={errors.lastName.length > 0}>
								{errors.lastName}
							</Alert>
							<label htmlFor="specialty">Специалност</label>
							<input
								className="form-input"
								type="text"
								name="specialty"
								value={doctorInfo.specialty}
								onChange={(e) => {
									setErrors((state) => ({
										...state,
										specialty: "",
									}));
									setDoctorInfo((state) => ({
										...state,
										specialty: e.target.value,
									}));
								}}
								onBlur={onBlurHandler}
							/>
							<Alert className="contact-form-alert" variant="danger" show={errors.specialty.length > 0}>
								{errors.specialty}
							</Alert>
							<span className="btn btn-primary btn-file">
								Избери снимка...
								<input
									className="create-doctor-file-upload-input"
									type="file"
									name="file"
									onChange={changeHandler}
								/>
							</span>
							{/* <Alert className="contact-form-alert" variant="danger" show={errors}>
							{errors}
						</Alert> */}
							<label htmlFor="description">Описание</label>
							<textarea
								className="create-doctor-textarea"
								rows="10"
								cols=""
								name="description"
								value={doctorInfo.description}
								onChange={(e) => {
									setErrors((state) => ({
										...state,
										description: "",
									}));
									setDoctorInfo((state) => ({
										...state,
										description: e.target.value,
									}));
								}}
								onBlur={onBlurHandler}
							></textarea>
							<Alert className="contact-form-alert" variant="danger" show={errors.description.length > 0}>
								{errors.description}
							</Alert>
							<input className="create-doctor-button" type="submit" value="Добави" />
						</form>
					)}
				</div>
			</div>
		</div>
	);
}
