import baseUrl from "../constants.js";

export const create = async (title, address, contacts) => {
	let res = await fetch(`${baseUrl}/practices`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			title: title,
			address: address,
			contacts: contacts,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getAllPractices = async () => {
	let res = await fetch(`${baseUrl}/practices`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const editPractice = async (id, practiceData) => {
	let res = await fetch(`${baseUrl}/practices/${id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(practiceData),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const deletePractice = async (id) => {
	let res = await fetch(`${baseUrl}/practices/delete`, {
		method: "DELETE",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			id: id,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getSinglePractice = (id) => {
	return fetch(`${baseUrl}/practices/${id}`).then((res) => res.json());
};
