import "./Footer.css";

export default function Footer() {
	return (
		<footer className="footer-wrapper">
			<div className="footer-info-wrapper">
				<div className="footer-rights">
					<p>&copy; 2022 ССОПЛ</p>
					<p>Всички права запазени</p>
				</div>
				<div className="footer-info">
					<p>Адрес: Стара Загора, ул. Д-р Тодор Стоянович №52</p>
					<p>
						Телефон за контакти:
						<a href="tel:+359887609034" title="Call +359887609034">
							0887609034
						</a>
					</p>
					<p>Емейл: contacts@ssopl.org</p>
				</div>
			</div>
			<div>
				<span class="designer">
					<a
						rel="noreferrer"
						href="https://iskam.site"
						target="_blank"
						class="iskam-site"
						title="Web Design &amp; multimedia; Programming; Software solutions; Graphic design &amp; corporate identity; Уеб дизайн и мултимедия; интернет решения; динамични уеб страници; графичен дизайн; корпоративна идентичност;"
					>
						Уеб дизайн и разработка 
						<img
							src="images/iskam.site.logo.png"
							alt="Logo Искам Сайт - Разработка на уеб сайтове Стара Загора"
							width="73px"
						/>
					</a>
				</span>
			</div>
		</footer>
	);
}
