import * as authSerivce from "../../../../services/authSerivce";
import { Alert } from "react-bootstrap";

import * as notificationService from "../../../../services/notificationService";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import "./Register.css";
import { useState } from "react";

let emailValidationRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export default function Register({ handleRegister }) {
	const [errors, setErrors] = useState({
		email: "",
		password: "",
		rePass: "",
	});
	const [registerInfo, setRegisterInfo] = useState({
		email: "",
		password: "",
		rePass: "",
	});

	const registerHandler = async (e) => {
		e.preventDefault();

		if (!registerInfo.email || !registerInfo.password || !registerInfo.rePass) {
			return notificationService.warning("Всички полета са задължителни!");
		}

		if (
			!emailValidationRegex.test(registerInfo.email.toString()) ||
			registerInfo.password.toString().length < 6 ||
			registerInfo.password !== registerInfo.rePass
		) {
			return notificationService.warning("Моля попълнете всичко полета коректно");
		}
		try {
			let authData = await authSerivce.register(
				registerInfo.email.toString(),
				registerInfo.password.toString(),
				registerInfo.rePass.toString()
			);
			// login(authData);
			handleRegister();
			notificationService.success("Регистрацията на нов потребител бе успешна!");
		} catch (error) {
			console.log(error);
			// notificationService.fail("Неуспешна регистрация!");
			errorHandlingService.messageHandler(error);
		}
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	const onPasswordChange = (e) => {
		let name = e.target.name;

		if (name === "password") {
			if (registerInfo.password === registerInfo.rePass) {
				setErrors((state) => ({
					...state,
					rePass: "",
				}));
			} else if (registerInfo.password !== registerInfo.rePass && registerInfo.rePass.length !== 0) {
				setErrors((state) => ({
					...state,
					rePass: "Паролите не съвпадат!",
				}));
			}
		} else if (name === "rePass") {
			registerInfo.password !== registerInfo.rePass
				? setErrors((state) => ({
						...state,
						rePass: "Паролите не съвпадат!",
				  }))
				: setErrors((state) => ({
						...state,
						rePass: "",
				  }));
		}
		// if (e.target.name === "password") {
		// 	const newPasswordInput = { ...passwordInput, password: e.target.value };
		// 	setPasswordInput(newPasswordInput);
		// } else {
		// 	const newPasswordInput = { ...passwordInput, confirmPassword: e.target.value };
		// 	setPasswordInput(newPasswordInput);
		// }
	};

	const onEmailChange = (e) => {
		!emailValidationRegex.test(registerInfo.email.toString())
			? setErrors((state) => ({
					...state,
					email: "Моля въведете валиден емаил адрес!",
			  }))
			: setErrors((state) => ({
					...state,
					email: "",
			  }));
	};

	return (
		<div className="register-page-wrapper">
			<div className="register-content-wrapper">
				<div className="register-image-wrapper">
					<img src="/images/login-register.png" alt="user register" />
				</div>
				<div className="register-form-wrapper">
					<h3>Създай нов акаунт</h3>
					<form className="register-form" method="POST" onSubmit={registerHandler}>
						<label htmlFor="email">Email</label>
						<input
							className="form-input"
							type="text"
							name="email"
							placeholder="ivan@abv.bg"
							value={registerInfo.email}
							onKeyUp={onEmailChange}
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									email: "",
								}));
								setRegisterInfo((state) => ({
									...state,
									email: e.target.value,
								}));
							}}
							onBlur={onBlurHandler}
						/>
						<Alert className="contact-form-alert" variant="danger" show={errors.email}>
							{errors.email}
						</Alert>
						<label htmlFor="password">Парола</label>
						<input
							className="form-input"
							type="password"
							name="password"
							placeholder="******"
							value={registerInfo.password}
							onKeyUp={onPasswordChange}
							onBlur={onBlurHandler}
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									password: "",
								}));
								setRegisterInfo((state) => ({
									...state,
									password: e.target.value,
								}));
							}}
						/>
						<Alert className="contact-form-alert" variant="danger" show={errors.password}>
							{errors.password}
						</Alert>
						<label htmlFor="rePass">Повтори Паролата</label>
						<input
							className="form-input"
							type="password"
							name="rePass"
							placeholder="*******"
							value={registerInfo.rePass}
							onKeyUp={onPasswordChange}
							onBlur={onBlurHandler}
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									rePass: "",
								}));
								setRegisterInfo((state) => ({
									...state,
									rePass: e.target.value,
								}));
							}}
						/>
						<Alert className="contact-form-alert" variant="danger" show={errors.rePass}>
							{errors.rePass}
						</Alert>
						<input className="register-button" type="submit" value="Регистрирай" />
					</form>
				</div>
			</div>
		</div>
	);
}

// const GuardedComponent = isLoggedInUser(Register);
// export default GuardedComponent;
