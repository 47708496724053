import { Link } from "react-router-dom";

import "./NotFoundPage.css";

export default function NotFound() {
	return (
		<div className="not-found-wrapper">
			<div className="not-found-text">
				<h3 className="not-found-heading">Страницата не съществува</h3>
				<Link className="not-found-button" to="/">
					Към началната страница
				</Link>
			</div>
			<div className="not-found-image">
				<img src="images/404/clueless-doctor-removebg-preview.png" alt="" />
			</div>
		</div>
	);
}
