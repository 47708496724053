import { useEffect, useState } from "react";
import * as newsService from "../../../services/newsService.js";
import * as notificationService from "../../../services/notificationService.js";
import Loader from "../../Shared/Loader/Loader.js";
import "./News.css";
import SingleNew from "./SingleNew/SingleNew.js";

export default function News() {
	const [news, setNews] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		newsService
			.getAllNews()
			.then((newsData) => {
				setNews(newsData);
				setIsLoading(false);
			})
			.catch((err) => notificationService.fail(err));
	}, []);

	const hasNews = news.slice(0, 5).map((neww, i) => <SingleNew key={neww._id} neww={neww} index={i} />);
	const noNews = <h3 className="home-news-no-news">Няма новини</h3>;
	return (
		<div className="home-news-wrapper">
			<h1>Последни новини</h1>
			<div className="home-news-carousel">{isLoading ? <Loader /> : news ? hasNews : noNews}</div>
		</div>
	);
}
