import { useState } from "react";
import { Alert } from "react-bootstrap";

import * as practicesServices from "../../../../services/practicesServices.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import "./CreatePractices.css";

export default function CreatePractices({ handleCreate }) {
	const [errors, setErrors] = useState({
		title: "",
		address: "",
		contacts: "",
	});
	const [practiceInfo, setPracticeInfo] = useState({
		title: "",
		address: "",
		contacts: "",
	});

	const createPracticeHandler = (e) => {
		e.preventDefault();

		if (!practiceInfo.title || !practiceInfo.address || !practiceInfo.contacts) {
			return notificationService.warning("Всички полета са задължителни!");
		}

		practicesServices
			.create(practiceInfo.title, practiceInfo.address, practiceInfo.contacts)
			.then((practiceData) => {
				handleCreate();
				notificationService.success("Успешно добавена практика");
				setPracticeInfo({
					title: "",
					address: "",
					contacts: "",
				});
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	return (
		<div className="create-practice-page-wrapper">
			<div className="create-practice-content-wrapper">
				<div className="create-practice-form-wrapper">
					<h3>Добави акредитирана практика</h3>
					<form className="create-practice-form" method="POST" onSubmit={createPracticeHandler}>
						<label htmlFor="title">Наименование</label>
						<input
							className="form-input"
							type="text"
							name="title"
							value={practiceInfo.title}
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									title: "",
								}));
								setPracticeInfo((state) => ({
									...state,
									title: e.target.value,
								}));
							}}
							onBlur={onBlurHandler}
						/>
						<Alert className="contact-form-alert" variant="danger" show={errors.title.length > 0}>
							{errors.title}
						</Alert>
						<label htmlFor="address">Адрес</label>
						<textarea
							className="create-practice-textarea"
							rows="10"
							cols=""
							name="address"
							value={practiceInfo.address}
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									address: "",
								}));
								setPracticeInfo((state) => ({
									...state,
									address: e.target.value,
								}));
							}}
							onBlur={onBlurHandler}
						></textarea>
						<Alert className="contact-form-alert" variant="danger" show={errors.address.length > 0}>
							{errors.address}
						</Alert>
						<label htmlFor="contacts">Контакти</label>
						<textarea
							className="create-practice-textarea"
							rows="10"
							cols=""
							name="contacts"
							value={practiceInfo.contacts}
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									contacts: "",
								}));
								setPracticeInfo((state) => ({
									...state,
									contacts: e.target.value,
								}));
							}}
							onBlur={onBlurHandler}
						></textarea>
						<Alert className="contact-form-alert" variant="danger" show={errors.contacts.length > 0}>
							{errors.contacts}
						</Alert>
						<input className="create-practice-button" type="submit" value="Добави" />
					</form>
				</div>
			</div>
		</div>
	);
}
