import { useEffect, useState } from "react";

import * as regulationsService from "../../services/regulationsService.js";
import * as errorHandlingService from "../../services/errorHandlingSerivce.js";
import ListPagination from "../Shared/ListPagination/ListPagination.js";
import Loader from "../Shared/Loader/Loader.js";

import "./LegalRegulationsPage.css";

export default function LegalRegulationsPage() {
	const [regulations, setRegulations] = useState([]);
	const [regulationsCount, setRegulationsCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		regulationsService
			.getRegulationsPaginated(0, 10)
			.then((regulationsData) => {
				setRegulations(regulationsData.regulations);
				setRegulationsCount(regulationsData.totalResults);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				errorHandlingService.messageHandler(err);
			});
	}, []);

	const currentPage = (value) => {
		setIsLoading(true);
		regulationsService
			.getRegulationsPaginated((value - 1) * 10, 10)
			.then((regulationsData) => {
				setRegulations(regulationsData.regulations);
				setIsLoading(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	};

	function convertDate(date) {
		let newDate = date.split("T")[0];
		return newDate;
	}

	const openInNewTab = (url) => {
		window.open(url, "_blank", "noopener,noreferrer");
	};

	const hasRegulations = regulations.map((regulation, i) => (
		<div
			key={regulation._id}
			className="regulations-page-single-regulation-wrapper"
			style={{ animationDelay: `${i / 2}s` }}
			onClick={() => openInNewTab(regulation.url)}
		>
			<div className="regulations-page-single-regulation-logo-title-wrapper">
				{regulation.file_type.includes("word") ? (
					<img className="regulation-img" alt="" src="/images/word.jpeg" />
				) : (
					<img className="regulation-img" alt="" src="/images/pdf.jpg" />
				)}
				<h3>{regulation.title.length > 100 ? regulation.title.substring(0, 100) + ' ...' : regulation.title}</h3>
			</div>
			<a href={regulation.url} target="_blank" rel="noopener noreferrer">
				{regulation.url}
			</a>
			{/* <p>{regulation.description.length > 400 ? regulation.description.substring(0, 400) + "..." : regulation.description}</p> */}
			<div className="home-single-regulation-date">{convertDate(regulation.created_at)}</div>
		</div>
	));

	const noRegulations = <h2 className="regulations-page-no-regulations">За момента няма нормативни актове</h2>;

	return (
		<div className="regulations-page-wrapper">
			<h1 className="regulations-page-heading">Нормативни актове</h1>
			<div className="regulations-pagination-wrapper">
				<ListPagination className="regulationsPagination" count={regulationsCount} currentPage={currentPage} />
			</div>
			{/* <Loader /> */}
			{isLoading ? <Loader /> : regulations.length > 0 ? hasRegulations : noRegulations}
		</div>
	);
}
