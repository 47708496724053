import ContactForm from "./ContactForm/ContactForm";
import ContactsHeader from "./ContactsHeader/ContactsHeader";

export default function Contacts() {
	return (
		<div className="contacts-wrapper">
			<ContactsHeader />
			<ContactForm />
		</div>
	);
}
