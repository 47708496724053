import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import * as doctorService from "../../../../services/doctorService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import Swal from "sweetalert2";

import "./Members.css";
import Loader from "../../../Shared/Loader/Loader.js";
import { useNavigate } from "react-router-dom";
import ListPagination from "../../../Shared/ListPagination/ListPagination.js";

export default function Members() {
	const [members, setMembers] = useState([]);
	const [allMembers, setAllMembers] = useState([]);
	const [membersCount, setMembersCount] = useState(0);
	const [error, setError] = useState("");
	const [uin, setUin] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useNavigate();

	const updateMembersState = () => {
		setIsLoading(true);
		doctorService
			.getMembersPaginated(0, 10)
			.then((membersData) => {
				setMembers(membersData.members);
				setMembersCount(membersData.totalResults);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				errorHandlingService.messageHandler(err);
			});
	};

	const getAllMembersState = () => {
		setIsLoading(true);
		doctorService
			.getAllMembers()
			.then((membersData) => {
				setAllMembers(membersData);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				errorHandlingService.messageHandler(err);
			});
	};

	const fileType = "application/vnd.openxmlformats.officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExt = ".xlsx";

	const exportToExcel = async () => {
		console.log('here');
		const ws = XLSX.utils.json_to_sheet(allMembers);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, "списък-членове" + fileExt);
	};

	useEffect(() => {
		updateMembersState();
		getAllMembersState();
	}, []);

	let emailValidationRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

	const addNewMemberHandler = async (e) => {
		e.preventDefault();

		const { value: formValues } = await Swal.fire({
			title: "Добави нов член",
			html:
				'<input id="firstName" class="swal2-input" placeholder="Име">' +
				'<input id="secondName" class="swal2-input" placeholder="Презиме">' +
				'<input id="lastName" class="swal2-input" placeholder="Фамилия">' +
				'<input id="phone_number" class="swal2-input" placeholder="Телефонен Номер">' +
				'<input id="email" class="swal2-input" placeholder="Емейл">' +
				'<input id="uin" class="swal2-input" placeholder="УИН">' +
				'<input id="practice" class="swal2-input" placeholder="Практика">',
			focusConfirm: false,
			showLoaderOnConfirm: true,
			confirmButtonText: "Добави",
			cancelButtonText: "Отказ",
			showCancelButton: true,
			preConfirm: () => {
				let data = {
					firstName: document.getElementById("firstName").value,
					secondName: document.getElementById("secondName").value,
					lastName: document.getElementById("lastName").value,
					phone_number: document.getElementById("phone_number").value,
					email: document.getElementById("email").value,
					uin: document.getElementById("uin").value.toString(),
					practice: document.getElementById("practice").value,
				};

				// if (
				// 	!data.firstName ||
				// 	!data.secondName ||
				// 	!data.lastName ||
				// 	!data.phone_number ||
				// 	!data.email ||
				// 	!data.uin ||
				// 	!data.practice
				// ) {
				// 	return Swal.showValidationMessage(`Всички полета са задължителни`);
				// }

				if (isNaN(Number(data.uin)) || data.uin.length !== 10) {
					return Swal.showValidationMessage(`УИН трябва да бъде 10 цифри`);
				}

				if (!emailValidationRegex.test(data.email)) {
					return Swal.showValidationMessage(`Моля въведете валиден емейл`);
				}
				return doctorService
					.createMember(data)
					.then(() => {
						updateMembersState();
						notificationService.success("Успешно добавен член");
					})
					.catch((error) => Swal.showValidationMessage(`Неуспешно добавен член! ${error}`));
			},
			allowOutsideClick: () => !Swal.isLoading(),
		});
	};

	const memberDeleteHandler = (id) => {
		doctorService
			.deleteMember(id)
			.then(() => {
				notificationService.success("Успешно изтрит член");
				updateMembersState();
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	};

	const memberEditHandler = (id) => {
		navigate(`/admin/members/edit/${id}`);
	};

	const findMemberByUin = () => {
		if (!uin) {
			updateMembersState();
			return setError("Моля въведете УИН!");
		}

		if (isNaN(Number(uin)) || uin.length !== 10) {
			return setError("УИН трябва да бъде 10 цифри!");
		}

		doctorService
			.getMemberByUin(uin)
			.then((memberData) => {
				if (!memberData.length) {
					return setError("Този УИН не беше намерен!");
				}
				setMembers(memberData);
				setUin("");
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	};

	const currentPage = (value) => {
		setIsLoading(true);
		doctorService
			.getMembersPaginated((value - 1) * 10, 10)
			.then((memberData) => {
				setMembers(memberData.members);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				errorHandlingService.messageHandler(err);
			});
	};

	const paymentHandler = async (memberId) => {
		console.log(memberId);

		const { value: formValues } = await Swal.fire({
			title: "Плащане на задължения",
			html: '<input id="amount" class="swal2-input" placeholder="Сума">',
			focusConfirm: false,
			showLoaderOnConfirm: true,
			confirmButtonText: "Плати",
			cancelButtonText: "Отказ",
			showCancelButton: true,
			preConfirm: () => {
				let data = {
					amount: document.getElementById("amount").value,
				};

				if (isNaN(Number(data.amount)) || Number(data.amount) <= 0) {
					return Swal.showValidationMessage(`Сумата трябва да е положително число`);
				}

				return doctorService
					.updateMemberObligations(memberId, data)
					.then(() => {
						updateMembersState();
						notificationService.success("Успешно плачане на задължение");
					})
					.catch((error) => Swal.showValidationMessage(`Неуспешно плачане на задължение! ${error}`));
			},
			allowOutsideClick: () => !Swal.isLoading(),
		});
	};

	const hasMembers = (
		<table>
			<thead>
				<tr>
					<th>Име</th>
					<th>Презиме</th>
					<th>Фамилия</th>
					<th>УИН</th>
					<th>Телефонен номер</th>
					<th>Емейл</th>
					<th>Практика</th>
					<th>Задължения</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{members.map((m) => (
					<tr key={m._id}>
						<td>{m.firstName[0].toUpperCase() + m.firstName.substring(1).toLowerCase()}</td>
						<td>{m.secondName[0].toUpperCase() + m.secondName.substring(1).toLowerCase()}</td>
						<td>{m.lastName[0].toUpperCase() + m.lastName.substring(1).toLowerCase()}</td>
						<td>{m.uin}</td>
						<td>{m.phone_number}</td>
						<td>{m.email}</td>
						<td>{m.practice}</td>
						<td>
							<div className="obligations-wrapper">
								{Number(m.obligations).toFixed(2)} лв.
								<button className="obligations-pay-button" onClick={() => paymentHandler(m._id)}>
									Плащане
								</button>
							</div>
						</td>
						<td>
							<button
								onClick={() => memberEditHandler(m._id)}
								className="memeber-edit-button"
								type="button"
							>
								Промени
							</button>
							<button
								type="button"
								className="memeber-delete-button"
								onClick={() => memberDeleteHandler(m._id)}
							>
								Изтрий
							</button>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);

	const noMembers = <h3 className="member-list-no-members">За момента няма членове</h3>;

	return (
		<div>
			<div className="new-member-wrapper">
				<div className="new-member-find-by-uin">
					<button className="new-member-button" type="button" onClick={findMemberByUin}>
						<i className="fa-solid fa-magnifying-glass"></i> Търси
					</button>
					<input
						type="text"
						name="uin"
						placeholder="УИН"
						onChange={(e) => {
							setError("");
							setUin(e.target.value);
						}}
						value={uin}
					/>
					<Alert className="new-member-wrapper-alert" variant="danger" show={error.length > 0}>
						{error}
					</Alert>
					<button className="excel-button" onClick={exportToExcel}>
						<img className="excel-button-logo" alt="" src="/images/excel.png"></img>
					</button>
				</div>
				<button type="button" className="new-member-button" onClick={addNewMemberHandler}>
					Добави нов член
				</button>
			</div>
			<div className="members-tab">
				<div className="news-tab-wrapper">
					<div className="admin-news-pagination">
						<ListPagination count={membersCount} currentPage={currentPage} />
					</div>
					{isLoading ? <Loader /> : members ? hasMembers : noMembers}
				</div>
			</div>
		</div>
	);
}
