import * as newsService from "../../services/newsService.js";
import * as errorHandlingService from "../../services/errorHandlingSerivce.js";

import { useEffect, useState } from "react";

import "./NewsPage.css";
import { useNavigate } from "react-router-dom";
import ListPagination from "../Shared/ListPagination/ListPagination.js";
import Loader from "../Shared/Loader/Loader.js";

export default function NewsPage() {
	const [news, setNews] = useState([]);
	const [newsCount, setNewsCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		newsService
			.getNewsPaginated(0, 10)
			.then((newsData) => {
				setNews(newsData.news);
				setNewsCount(newsData.totalResults);
				setIsLoading(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}, []);

	const currentPage = (value) => {
		setIsLoading(true);
		newsService
			.getNewsPaginated((value - 1) * 10, 10)
			.then((newsData) => {
				setNews(newsData.news);
				setIsLoading(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	};

	const newsClickHandler = (id) => {
		navigate(`/news/${id}`);
	};

	function convertDate(date) {
		let newDate = date.split("T")[0];
		return newDate;
	}

	const openInNewTab = (neww) => {
		console.log(neww);
		if (neww.url) {
			window.open(neww.url, "_blank", "noopener,noreferrer");
		} else {
			navigate(`/news/${neww._id}`);
		}
	};

	const hasNews = news.map((neww, i) => (
		<div
			key={neww._id}
			className="news-page-single-new-wrapper"
			style={{ animationDelay: `${i / 2}s` }}
			onClick={() => openInNewTab(neww)}
		>
			<div className="news-page-single-regulation-logo-title-wrapper">
				{neww.file_type ? (
					neww.file_type.includes("word") ? (
						<img className="regulation-img" alt="" src="/images/word.jpeg" />
					) : (
						<img className="regulation-img" alt="" src="/images/pdf.jpg" />
					)
				) : (
					""
				)}
				<h3>{neww.title.length > 100 ? neww.title.substring(0, 100) + " ..." : neww.title}</h3>
			</div>
			<a href={neww.url} target="_blank" rel="noopener noreferrer">
				{neww.url}
			</a>
			<div className="home-single-new-date">{convertDate(neww.created_at)}</div>
		</div>
	));

	const noNews = <h2 className="news-page-no-news">За момента няма новини</h2>;

	return (
		<div className="news-page-wrapper">
			<h1 className="news-page-heading">Новини</h1>
			<div className="news-pagination-wrapper">
				<ListPagination className="newsPagination" count={newsCount} currentPage={currentPage} />
			</div>
			{/* <Loader /> */}
			{isLoading ? <Loader /> : news.length > 0 ? hasNews : noNews}
		</div>
	);
}
