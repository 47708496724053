import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import * as authService from "../../../services/authSerivce";
import * as errorHandlingService from "../../../services/errorHandlingSerivce.js";

import User from "./User/User.js";
import Register from "./Register/Register.js";
import "./Users.css";
import Loader from "../../Shared/Loader/Loader.js";

export default function Users() {
	const [users, setUsers] = useState([]);
	const [key, setKey] = useState("user-list");
	const [isLoading, setIsLoading] = useState(true);

	function updateUsersState() {
		setIsLoading(true);
		authService
			.getUsers()
			.then((userData) => {
				setUsers(userData);
				setIsLoading(false);
			})
			.catch((error) => {
				errorHandlingService.messageHandler(error);
			});
	}

	useEffect(() => {
		updateUsersState();
	}, []);

	const handleRegister = () => {
		updateUsersState();
		setKey("user-list");
	};

	const handleDelete = () => {
		updateUsersState();
		setKey("user-list");
	};

	return (
		<div className="users-wrapper">
			<Tabs
				id="controlled-tab-users"
				activeKey={key}
				onSelect={(k) => {
					setKey(k);
				}}
				className="mb-3"
			>
				<Tab eventKey="user-list" title="Списък">
					<div className="user-tab">
						{isLoading ? (
							<Loader />
						) : (
							<table>
								<thead>
									<tr>
										<th className="id-column">ID</th>
										<th>Email</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{users.map((user) => (
										<User key={user._id} user={user} handleDelete={handleDelete}></User>
									))}
								</tbody>
							</table>
						)}
					</div>
				</Tab>
				<Tab eventKey="user-register" title="Регистрация">
					<Register handleRegister={handleRegister} />
				</Tab>
			</Tabs>
		</div>
	);
}
