import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";

import * as notificationService from "../../../../services/notificationService.js";
import * as usefulLinksService from "../../../../services/usefulLinksService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import "./CreateUsefulLink.css";

import * as filestack from "filestack-js";
import Loader from "../../../Shared/Loader/Loader.js";
const client = filestack.init("AI1Fw5JZR7atkwYVFDSSbz");

export default function CreateUsefulLink({ handleCreate }) {
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [linksCount, setLinksCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [errors, setErrors] = useState({
		title: "",
		link: "",
	});
	const [linkInfo, setLinkInfo] = useState({
		title: "",
		link: "",
	});

	useEffect(() => {
		setIsLoading(true);
		usefulLinksService
			.getLinksPaginated(0, 10)
			.then((linksData) => {
				setLinksCount(linksData.totalResults);
				setIsLoading(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}, []);

	const createLinksHandler = (e) => {
		e.preventDefault();

		if (!linkInfo.title || !linkInfo.link) {
			return notificationService.warning("Всички полета са задължителни!");
		}

		if (!isFilePicked) {
			return notificationService.warning("Не сте избрали снимка!");
		}

		let formData = new FormData();
		formData.append("file", selectedFile.data);

		setIsLoading(true);
		client
			.upload(selectedFile.data)
			.then((data) => {
				let order = linksCount + 1;
				usefulLinksService
					.create(data.url, linkInfo.title, linkInfo.link, order)
					.then((linkData) => {
						handleCreate();
						setLinkInfo({
							title: "",
							link: "",
						});
						setSelectedFile("");
						setIsFilePicked(false);
						setIsLoading(false);
						notificationService.success("Успешно добавена връзка");
					})
					.catch((err) => {
						setIsLoading(false);
						errorHandlingService.messageHandler(err);
					});
			})
			.catch((err) => console.log(err));
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	const changeHandler = (e) => {
		const img = {
			preview: URL.createObjectURL(e.target.files[0]),
			data: e.target.files[0],
		};
		setSelectedFile(img);
		setIsFilePicked(true);
	};

	return (
		<div className="create-links-page-wrapper">
			<div className="create-links-content-wrapper">
				{isLoading ? (
					<Loader />
				) : (
					<div className="create-links-form-wrapper">
						<h3>Добави нова връзка</h3>
						<form className="create-links-form" method="POST" onSubmit={createLinksHandler}>
							<span className="btn btn-primary btn-file">
								Избери лого...
								<input
									className="create-link-file-upload-input"
									type="file"
									name="file"
									onChange={changeHandler}
								/>
							</span>
							{isFilePicked ? <span>{selectedFile.data.name}</span> : ""}
							<label htmlFor="title">Заглавие</label>
							<input
								className="form-input"
								type="text"
								name="title"
								value={linkInfo.title}
								onChange={(e) => {
									setErrors((state) => ({
										...state,
										title: "",
									}));
									setLinkInfo((state) => ({
										...state,
										title: e.target.value,
									}));
								}}
								onBlur={onBlurHandler}
							/>
							<Alert className="contact-form-alert" variant="danger" show={errors.title.length > 0}>
								{errors.title}
							</Alert>
							<label htmlFor="link">Линк</label>
							<input
								className="form-input"
								name="link"
								type="text"
								value={linkInfo.link}
								onChange={(e) => {
									setErrors((state) => ({
										...state,
										link: "",
									}));
									setLinkInfo((state) => ({
										...state,
										link: e.target.value,
									}));
								}}
								onBlur={onBlurHandler}
							></input>
							<Alert className="contact-form-alert" variant="danger" show={errors.link.length > 0}>
								{errors.link}
							</Alert>
							<input className="create-links-button" type="submit" value="Добави" />
						</form>
					</div>
				)}
			</div>
		</div>
	);
}
