import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";

import * as practicesServices from "../../../../services/practicesServices.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import './EditPractice.css'

export default function EditPractice() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [errors, setErrors] = useState({
		title: "",
		address: "",
		contacts: "",
	});
	const [practiceInfo, setPracticeInfo] = useState({
		title: "",
		address: "",
		contacts: "",
	});

	useEffect(() => {
		practicesServices
			.getSinglePractice(id)
			.then((practiceData) => {
				setPracticeInfo({
					title: practiceData.title,
					address: practiceData.address,
					contacts: practiceData.contacts,
				});
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	}, []);

	const editPracticeCancelHandler = () => {
		navigate("/admin/accredited-practices");
	};

	const editPracticeSubmitHandler = (e) => {
		e.preventDefault();

		practicesServices
			.editPractice(id, {
				title: practiceInfo.title,
				address: practiceInfo.address,
				contacts: practiceInfo.contacts,
			})
			.then(() => {
				notificationService.success(`Успешно променена практика`);
				navigate("/admin/accredited-practices");
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	return (
		<div className="edit-practice-wrapper">
			<div className="edit-practice-content-wrapper">
				<form onSubmit={editPracticeSubmitHandler}>
					<label htmlFor="">Наименование</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								title: "",
							}));
							setPracticeInfo((state) => ({
								...state,
								title: e.target.value,
							}));
						}}
						className="edit-practice-form-input"
						type="text"
						name="title"
						value={practiceInfo.title}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.title.length > 0}>
						{errors.title}
					</Alert>
					<label htmlFor="address">Адрес</label>
					<textarea
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								address: "",
							}));
							setPracticeInfo((state) => ({
								...state,
								address: e.target.value,
							}));
						}}
						className="edit-practice-form-input"
						rows="10"
						cols=""
						name="address"
						value={practiceInfo.address}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.address.length > 0}>
						{errors.address}
					</Alert>
					<label htmlFor="contacts">Контакти</label>
					<textarea
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								contacts: "",
							}));
							setPracticeInfo((state) => ({
								...state,
								contacts: e.target.value,
							}));
						}}
						className="edit-practice-form-input"
						rows="10"
						cols=""
						name="contacts"
						value={practiceInfo.contacts}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.contacts.length > 0}>
						{errors.contacts}
					</Alert>
					<div className="edit-practice-button-wrapper">
						<input
							className="edit-practice-button edit-practice-edit-button"
							type="submit"
							value="Промени"
						/>
						<Link
							onClick={editPracticeCancelHandler}
							className="edit-practice-button edit-practice-deletes-button"
							to={"/admin/accredited-practices"}
						>
							Отказ
						</Link>
					</div>
				</form>
			</div>
		</div>
	);
}
