import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import * as newsService from "../../services/newsService.js";
import * as errorHandlingService from "../../services/errorHandlingSerivce.js";

import "./NewsDetailsPage.css";

export default function NewsDetailsPage() {
	const { id } = useParams();

	const [neww, setNeww] = useState({});

	useEffect(() => {
		newsService
			.getSingleNew(id)
			.then((newData) => setNeww(newData))
			.catch((err) => errorHandlingService.messageHandler(err));
	}, []);

	return (
		<div className="news-detail-page-wrapper">
			<h1>{neww.title}</h1>
			<p className="news-details-main-text">{neww.description}</p>
		</div>
	);
}
