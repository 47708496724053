import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Alert } from "react-bootstrap";

import * as doctorService from "../../../../services/doctorService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

export default function EditMember() {
	const { id } = useParams();
	const navigate = useNavigate();

	const [memberInfo, setMemberInfo] = useState({
		firstName: "",
		secondName: "",
		lastName: "",
		phone_number: "",
		email: "",
		uin: "",
		practice: "",
		obligations: "",
	});
	const [errors, setErrors] = useState({
		firstName: "",
		secondName: "",
		lastName: "",
		phone_number: "",
		email: "",
		uin: "",
		practice: "",
		obligations: "",
	});

	useEffect(() => {
		doctorService
			.getSingleMember(id)
			.then((memberData) => {
				setMemberInfo({
					firstName: memberData.firstName,
					secondName: memberData.secondName,
					lastName: memberData.lastName,
					phone_number: memberData.phone_number,
					email: memberData.email,
					uin: memberData.uin,
					practice: memberData.practice,
					obligations: memberData.obligations,
				});
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}, []);

	const editMemberCancelHandler = () => {
		navigate("/admin/docs");
	};

	const editMemberSubmitHandler = (e) => {
		e.preventDefault();

		if (isNaN(Number(memberInfo.obligations))) {
			return notificationService.warning('Задължението трябва да съдържа само цифри!')
		}

		doctorService
			.editMember(id, {
				firstName: memberInfo.firstName,
				secondName: memberInfo.secondName,
				lastName: memberInfo.lastName,
				phone_number: memberInfo.phone_number,
				email: memberInfo.email,
				uin: memberInfo.uin,
				practice: memberInfo.practice,
				obligations: memberInfo.obligations
			})
			.then((data) => {
				notificationService.success("Успешно променен член");
				navigate("/admin/docs");
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	};

	return (
		<div className="edit-doctor-page-wrapper">
			<div className="edit-doctor-content-wrapper">
				<form onSubmit={editMemberSubmitHandler}>
					<label htmlFor="firstName">Име</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								firstName: "",
							}));
							setMemberInfo((state) => ({
								...state,
								firstName: e.target.value,
							}));
						}}
						className="edit-doctor-form-input"
						type="text"
						name="firstName"
						value={memberInfo.firstName}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.firstName.length > 0}>
						{errors.firstName}
					</Alert>
					<label htmlFor="secondName">Презиме</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								secondName: "",
							}));
							setMemberInfo((state) => ({
								...state,
								secondName: e.target.value,
							}));
						}}
						className="edit-doctor-form-input"
						type="text"
						name="secondName"
						value={memberInfo.secondName}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.secondName.length > 0}>
						{errors.secondName}
					</Alert>
					<label htmlFor="lastName">Фамилия</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								lastName: "",
							}));
							setMemberInfo((state) => ({
								...state,
								lastName: e.target.value,
							}));
						}}
						className="edit-doctor-form-input"
						type="text"
						name="lastName"
						value={memberInfo.lastName}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.lastName.length > 0}>
						{errors.lastName}
					</Alert>
					<label htmlFor="uin">УИН</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								uin: "",
							}));
							setMemberInfo((state) => ({
								...state,
								uin: e.target.value,
							}));
						}}
						className="edit-doctor-form-input"
						type="text"
						name="uin"
						value={memberInfo.uin}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.uin.length > 0}>
						{errors.uin}
					</Alert>
					<label htmlFor="phone_number">Телефонен номер</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								phone_number: "",
							}));
							setMemberInfo((state) => ({
								...state,
								phone_number: e.target.value,
							}));
						}}
						className="edit-doctor-form-input"
						type="text"
						name="phone_number"
						value={memberInfo.phone_number}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.phone_number.length > 0}>
						{errors.phone_number}
					</Alert>
					<label htmlFor="email">Емейл</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								email: "",
							}));
							setMemberInfo((state) => ({
								...state,
								email: e.target.value,
							}));
						}}
						className="edit-doctor-form-input"
						type="text"
						name="email"
						value={memberInfo.email}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.email.length > 0}>
						{errors.email}
					</Alert>
					<label htmlFor="practice">Практика</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								practice: "",
							}));
							setMemberInfo((state) => ({
								...state,
								practice: e.target.value,
							}));
						}}
						className="edit-doctor-form-input"
						type="text"
						name="practice"
						value={memberInfo.practice}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.practice.length > 0}>
						{errors.practice}
					</Alert>
					<label htmlFor="obligations">Задължения</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								obligations: "",
							}));
							setMemberInfo((state) => ({
								...state,
								obligations: e.target.value,
							}));
						}}
						className="edit-doctor-form-input"
						type="text"
						name="obligations"
						value={memberInfo.obligations}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.obligations.length > 0}>
						{errors.obligations}
					</Alert>
					<div className="edit-doctor-button-wrapper">
						<input className="edit-doctor-button edit-doctor-edit-button" type="submit" value="Промени" />
						<Link
							onClick={editMemberCancelHandler}
							className="edit-doctor-button edit-doctor-deletes-button"
							to={"/admin/docs"}
						>
							Отказ
						</Link>
					</div>
				</form>
			</div>
		</div>
	);
}
