import * as authService from "../../../../services/authSerivce.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import "./User.css";

export default function User({ user, handleDelete }) {
	function deleteHandler() {
		authService
			.deleteUser(user._id)
			.then(() => {
				notificationService.success("Потребителят беше изтрит успешно");
				handleDelete();
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}

	return (
		<tr className="user-row">
			<td className="id-column">{user._id}</td>
			<td>{user.email}</td>
			<td>
				<button onClick={deleteHandler} className="user-delete-button" type="button">
					Изтрий
				</button>
			</td>
		</tr>
	);
}
