import "./GoToTop.css";

export default function GoToTop() {
	const goToTopClickHandler = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<div className="go-to-top-button" onClick={goToTopClickHandler}>
			<i className="fa-solid fa-circle-arrow-up"></i>
		</div>
	);
}
