import { useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";


import "./ApplyFormPage.css";

import * as notificationService from '../../services/notificationService.js'

export default function ApplyFormPage() {
	const [formDetails, setFormDetails] = useState({
		firstName: "",
		secondName: "",
		lastName: "",
		uin: "",
		phoneNumber: "",
		email: "",
		practice: "",
	});
	const [errors, setErrors] = useState({
		firstName: "",
		secondName: "",
		lastName: "",
		uin: "",
		phoneNumber: "",
		email: "",
		practice: "",
	});

	const navigate = useNavigate();
	const form = useRef();

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	const applyFormSubmitHandler = (e) => {
		e.preventDefault();

		if (
			!formDetails.firstName ||
			!formDetails.secondName ||
			!formDetails.lastName ||
			!formDetails.uin ||
			!formDetails.phoneNumber ||
			!formDetails.email ||
			!formDetails.practice
		) {
			return notificationService.warning("Всички полета са задължителни!");
		}

		emailjs
			.sendForm("service_y4car4m", "template_nwg12cc", form.current, "N5JDVrHyLj14Tnpma")
			.then(() => {
				notificationService.success("Успешно изпратено съобщениие");
				navigate("/thankyou");
			})
			.catch((err) => notificationService.fail("Грешка: " + err));
	};

	return (
		<div className="apply-page-wrapper">
			<div className="apply-page-content-wrapper">
				{/* <img className="apply-page-img" src="/images/apply-page2.jpg" alt="" /> */}
				<h3 className="apply-page-header">Форма за кандидатура за нов член на сдружението</h3>
				<form ref={form} className="apply-page-form" onSubmit={applyFormSubmitHandler}>
					<label className="apply-page-form-label" htmlFor="firstName">
						Име
					</label>
					<input
						className="apply-page-form-input"
						type="text"
						name="firstName"
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								firstName: "",
							}));
							setFormDetails((state) => ({
								...state,
								firstName: e.target.value,
							}));
						}}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.firstName.length > 0}>
						{errors.firstName}
					</Alert>
					<label className="apply-page-form-label" htmlFor="secondName">
						Презиме
					</label>
					<input
						className="apply-page-form-input"
						type="text"
						name="secondName"
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								secondName: "",
							}));
							setFormDetails((state) => ({
								...state,
								secondName: e.target.value,
							}));
						}}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.secondName.length > 0}>
						{errors.secondName}
					</Alert>
					<label className="apply-page-form-label" htmlFor="lastName">
						Фамилия
					</label>
					<input
						className="apply-page-form-input"
						type="text"
						name="lastName"
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								lastName: "",
							}));
							setFormDetails((state) => ({
								...state,
								lastName: e.target.value,
							}));
						}}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.lastName.length > 0}>
						{errors.lastName}
					</Alert>
					<label className="apply-page-form-label" htmlFor="uin">
						УИН
					</label>
					<input
						className="apply-page-form-input"
						type="text"
						name="uin"
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								uin: "",
							}));
							setFormDetails((state) => ({
								...state,
								uin: e.target.value,
							}));
						}}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.uin.length > 0}>
						{errors.uin}
					</Alert>
					<label className="apply-page-form-label" htmlFor="practice">
						Телефонен номер
					</label>
					<input
						className="apply-page-form-input"
						type="text"
						name="phoneNumber"
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								phoneNumber: "",
							}));
							setFormDetails((state) => ({
								...state,
								phoneNumber: e.target.value,
							}));
						}}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.phoneNumber.length > 0}>
						{errors.phoneNumber}
					</Alert>
					<label className="apply-page-form-label" htmlFor="practice">
						Емейл
					</label>
					<input
						className="apply-page-form-input"
						type="text"
						name="email"
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								email: "",
							}));
							setFormDetails((state) => ({
								...state,
								email: e.target.value,
							}));
						}}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.email.length > 0}>
						{errors.email}
					</Alert>
					<label className="apply-page-form-label" htmlFor="practice">
						Име на практика
					</label>
					<input
						className="apply-page-form-input"
						type="text"
						name="practice"
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								practice: "",
							}));
							setFormDetails((state) => ({
								...state,
								practice: e.target.value,
							}));
						}}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.practice.length > 0}>
						{errors.practice}
					</Alert>
					<ReCAPTCHA sitekey="6LdY7d8iAAAAAEy9pYIvkadFyj_6wY-AiFrldI-i" />
					<input className="apply-page-submit-button" type="submit" name="" value="Подай заявление" />
				</form>
			</div>
		</div>
	);
}
