import { useNavigate } from "react-router-dom";
import * as doctorService from "../../../../services/doctorService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";
import "./Doctor.css";

export default function Doctor({ doctor, handleDelete }) {
	const navigate = useNavigate();

	const deleteDoctorHandler = () => {
		doctorService
			.deleteDoctor(doctor._id)
			.then(() => {
				notificationService.success("Успешно изтрит доктор");
				handleDelete();
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	};

	const editDoctorHandler = () => {
		navigate(`/admin/docs/edit/${doctor._id}`);
	};

	return (
		<tr className="doctor-row">
			<td>{doctor.firstName}</td>
			<td>{doctor.lastName}</td>
			<td >{doctor.specialty}</td>
			<td className="doctor-row-description ">{doctor.description}</td>
			<td>
				<button onClick={editDoctorHandler} className="doctor-button doctor-edit" type="button">
					Промени
				</button>
				<button onClick={deleteDoctorHandler} className="doctor-button doctor-delete" type="button">
					Изтрий
				</button>
			</td>
		</tr>
	);
}
