import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

import * as usefulLinksService from "../../../../services/usefulLinksService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import "./SingleLink.css";
import { useState } from "react";

export default function SingleLink({ link, handleDelete, linksCount, handleChangeOrder, handleDeleteReorder }) {
	const [oldOrder, setOldOrder] = useState();
	const navigate = useNavigate();

	const editLinkHandler = () => {
		navigate(`/admin/useful-links/edit/${link._id}`);
	};

	const deleteLinkHandler = () => {
		handleDeleteReorder(link.order);
		usefulLinksService
			.deleteLink(link._id)
			.then(() => {
				notificationService.success("Успешно изтрита връзка");
				handleDelete();
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	};

	const onMoveUp = () => {
		handleChangeOrder("up", link.order);
		link.order = link.order - 1;
		usefulLinksService
			.editLink(link._id, link)
			.then((data) => {
				notificationService.warning("Редът на линковете е променен");
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	};

	const onMoveDown = () => {
		handleChangeOrder("down", link.order);
		link.order = link.order + 1;
		usefulLinksService
			.editLink(link._id, link)
			.then((data) => {
				notificationService.warning("Редът на линковете е променен");
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	};

	return (
		<tr className="link-row">
			<td className="link-arrows-td">
				{link.order > 1 ? (
					<FontAwesomeIcon onClick={onMoveUp} icon={solid("circle-chevron-up")}></FontAwesomeIcon>
				) : (
					""
				)}
				{link.order < linksCount ? (
					<FontAwesomeIcon onClick={onMoveDown} icon={solid("circle-chevron-down")}></FontAwesomeIcon>
				) : (
					""
				)}
			</td>
			<td className="link-logo-wrapper">
				<img className="link-logo" alt="" src={link.logo}></img>
			</td>

			<td>{link.title}</td>
			<td className="hidden">
				<a href={link.link} target="_blank" rel="noopener noreferrer">
					{link.link}
				</a>
			</td>
			<td>
				<button onClick={editLinkHandler} className="links-button links-edit" type="button">
					Промени
				</button>
				<button onClick={deleteLinkHandler} className="links-button links-delete" type="button">
					Изтрий
				</button>
			</td>
		</tr>
	);
}
