import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Loader from "../../Shared/Loader/Loader.js";

import * as practicesService from "../../../services/practicesServices.js";
import * as errorHandlingService from "../../../services/errorHandlingSerivce.js";

import "./AccreditedPractices.css";
import CreatePractices from "./CreatePractices/CreatePractices.js";
import SinglePractice from "./SinglePractice/SinglePractice.js";

export default function AccreditedPractices() {
	const [key, setKey] = useState("practices-list");
	const [practices, setPractices] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		updatePracticesState();
	}, []);

	function updatePracticesState() {
		practicesService
			.getAllPractices()
			.then((practiceData) => {
				setPractices(practiceData);
				setIsLoading(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}

	const handleCreate = () => {
		updatePracticesState();
		setKey("practices-list");
	};

	const handleDelete = () => {
		updatePracticesState();
		setKey("news-list");
	};

	const hasPractices = (
		<table>
			<thead>
				<tr>
					<th>Наименование</th>
					<th>Адрес</th>
					<th>Контакти</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{practices.map((practice) => (
					<SinglePractice key={practice._id} practice={practice} handleDelete={handleDelete} />
				))}
			</tbody>
		</table>
	);

	const noPractices = (
		<h3 className="accredited-practices-list-no-accredited-practices">За момента няма акредитирани практики</h3>
	);

	return (
		<div className="accredited-practices-wrapper">
			<Tabs
				id="controlled-tab-practices"
				activeKey={key}
				onSelect={(k) => {
					setKey(k);
				}}
				className="mb-3"
			>
				<Tab eventKey="practices-list" title="Списък">
					<div className="accredited-practices-tab-wrapper">
						{isLoading ? (
							<Loader className="admin-accredited-practices-loader" />
						) : practices.length > 0 ? (
							hasPractices
						) : (
							noPractices
						)}
					</div>
				</Tab>
				<Tab eventKey="accredited-practices-create" title="Нова практика">
					<CreatePractices handleCreate={handleCreate} />
				</Tab>
			</Tabs>
		</div>
	);
}
