import { useNavigate } from "react-router-dom";
import * as regulationsService from "../../../../services/regulationsService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import "./SingleRegulation.css";

export default function SingleRegulation({ regulation, handleDelete }) {
	const navigate = useNavigate();

	const deleteRegulationHandler = () => {
		regulationsService
			.deleteRegulation(regulation._id)
			.then(() => {
				notificationService.success("Успешно изтрит нормативен акт");
				handleDelete();
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	};

	const editRegulationHandler = () => {
		navigate(`/admin/legal-regulation/edit/${regulation._id}`);
	};

	return (
		<tr className="regulation-row">
			<td className="regulation-logo">
				{regulation.file_type.includes("word") ? (
					<img alt="" src="/images/word.jpeg" />
				) : (
					<img alt="" src="/images/pdf.jpg" />
				)}
			</td>
			<td>{regulation.title.length > 300 ? regulation.title.substring(0, 300) + " ..." : regulation.title}</td>
			<td>
				<a href={regulation.url} target="_blank" rel="noopener noreferrer">
					{regulation.url}
				</a>
			</td>
			<td>
				<button onClick={editRegulationHandler} className="regulation-button regulation-edit" type="button">
					Промени
				</button>
				<button onClick={deleteRegulationHandler} className="regulation-button regulation-delete" type="button">
					Изтрий
				</button>
			</td>
		</tr>
	);
}
