import { useEffect, useState } from "react";
import "./About.css";
import DoctorCard from "./DoctorCard/DoctorCard";
import * as doctorService from "../../services/doctorService.js";
import * as errorHandlingService from "../../services/errorHandlingSerivce.js";
import Loader from "../Shared/Loader/Loader.js";

export default function About() {
	const [doctors, setDoctors] = useState([]);
	const [members, setMembers] = useState([]);
	const [isLoadingDocs, setIsLoadingDocs] = useState(true);
	const [isLoadingMembers, setIsLoadingMembers] = useState(true);

	useEffect(() => {
		doctorService
			.getAllDoctors()
			.then((doctorsData) => {
				setDoctors(doctorsData);
				setIsLoadingDocs(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}, []);

	useEffect(() => {
		doctorService
			.getAllMembers()
			.then((membersData) => {
				setMembers(membersData);
				setIsLoadingMembers(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}, []);

	return (
		<div className="about-wrapper">
			<h2>Запознайте се с нашият управителен съвет</h2>
			<div className="cards-wrapper">
				{isLoadingDocs ? (
					<Loader />
				) : (
					doctors.map((doctor, i) => <DoctorCard key={doctor._id} doctor={doctor} index={i} />)
				)}
			</div>
			{/* <div className="members">
				<h2>Списък с членове</h2>
				<ul className="members-list">
					{isLoadingMembers ? (
						<Loader />
					) : (
						members.map((m) => (
							<li key={m._id}>
								{m.firstName[0].toUpperCase() + m.firstName.substring(1).toLowerCase()}{" "}
								{m.secondName[0].toUpperCase() + m.secondName.substring(1).toLowerCase()}{" "}
								{m.lastName[0].toUpperCase() + m.lastName.substring(1).toLowerCase()}
							</li>
						))
					)}
				</ul>
			</div> */}
		</div>
	);
}
