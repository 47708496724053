import "./ContactsHeader.css";

export default function ContactsHeader() {
	return (
		<div className="contacts-header-content">
			<div className="contacts-header-wrapper">
				<h1>KOНТАКТИ</h1>
				<p>
					Не се притеснявайте да да ни потърсите, когато ви е удобно и ние с удоволствие ще се свържем с
					вас.
				</p>
			</div>
		</div>
	);
}
