import './Statute.css'

export default function Statute() {
	
	return (
		<div className="statute-page-wrapper">
			<p align="center">
				<strong>СТАРОЗАГОРСКО СДРУЖЕНИЕ НА ОБЩОПРАКТИКУВАЩИТЕ ЛЕКАРИ /ССОПЛ/</strong>
				<strong> </strong>
				<strong></strong>
			</p>
			<div align="center">
				<hr size="2" width="100%" noshade="" align="center" />
			</div>
			<p align="center">
				<strong>
					<u>УСТАВ</u>
				</strong>
			</p>
			<div align="center">
				<hr size="2" width="100%" noshade="" align="center" />
			</div>
			<p align="center">
				<strong>
					ГЛАВА I
					<br />
					НАИМЕНОВАНИЕ, СЕДАЛИЩЕ, ЦЕЛИ, СРЕДСТВА
				</strong>
			</p>
			<p>
				<strong>
					<br />
					Чл.1
				</strong>
				(1)<em> </em>Старозагорското сдружение на общопрактикуващите лекари (ССОПЛ) е неправителствено,
				доброволно, независимо, нерелигиозно и неполитическо сдружение с нестопанска цел на физически и/или
				юридически лица - български правоспособни общопрактикуващи лекари и преподаватели по Обща медицина,
				организирани в регионално сдружение, за защита на общите им интереси.
			</p>
			<p>
				(2) ССОПЛ е самостоятелно сдружение към Българския лекарски съюз (БЛС), съгласно чл.3, ал.3 от Устава на
				БЛС.
			</p>
			<p>
				(3) ССОПЛ осъществява цялостната си дейност въз основа на този Устав, решенията на Общото събрание на
				ССОПЛ и действащите закони.
			</p>
			<p>(4) Сдружението има запазено и защитено от закона наименование, знак и печат.</p>
			<p>
				<strong>Чл.2</strong>
				(1) ССОПЛ е сдружение с нестопанска цел, юридическо лице, което осъществява дейността си в частна полза
				на своите членове.
			</p>
			<p>
				(2) Наименованието на сдружението е - <strong>СТАРОЗАГОРСКО</strong>{" "}
				<strong>СДРУЖЕНИЕ НА ОБЩОПРАКТИКУВАЩИТЕ ЛЕКАРИ</strong>, наричано за краткост в този Устав ССОПЛ или
				сдружение. На латиница наименованието на сдружението се изписва: <strong>Regional</strong>
				<strong>Association of General Practitioners in Stara Zagora /RAGPSZ/</strong>
			</p>
			<p>
				<strong>Чл.3</strong>
				ССОПЛ е със седалище: гр.Стара Загора и адрес на управление
				<strong>
					<u>ул."Д-р Тодор Стоянович“№52 каб.№12. </u>
				</strong>{" "}
				<strong>
					<u></u>
				</strong>
			</p>
			<p>
				<strong>Чл.4</strong>
				ССОПЛ си поставя следните цели:
			</p>
			<p>
				1. Да осигури правилно и широко популяризирано сред обществеността разбиране за същността на
				специалността Обща медицина и функциите на ОПЛ;
			</p>
			<p>
				2. Да развива връзките си с обществеността и информационния обмен, за да разясни на хората какво да
				очакват от една специалност, която има широк обхват и разнообразие от дейности;
			</p>
			<p>
				3. Да защитава правата, професионалните, социалните и икономическите интереси на общопрактикуващите
				лекари;
			</p>
			<p>4. Да подкрепя зачитането на принципите на медицинската етика и деонтология;</p>
			<p>5. Да спечели уважение за специалността Обща медицина в академичните кръгове.</p>
			<p>6. Да осигурява своевременна информация на своите членове</p>
			<p>7. Сътрудничество в регионален, национален и международен план.</p>
			<p>
				<strong>Чл.5</strong>
				Основните средства за постигане на целите на сдружението са:
			</p>
			<p>1. Организиране на форуми за дискутиране на проблемите на общопрактикуващите лекари;</p>
			<p>2. Организиране на електронен сайт и участие в медиите.</p>
			<p>
				3. Създаване на лоби в академичните, политически и други кръгове, които влияят пряко или косвено върху
				професионалните и икономически интереси на ОПЛ.
			</p>
			<p>
				4. Контакти, съвместна дейност, асоцииране с други правителствени и неправителствени организации в
				страната и чужбина;
			</p>
			<p>
				5. Обединяване на академизма с практиката - участие в изготвяне на програми за обучение на
				общопрактикуващи лекари, изработването на стандарти за обема и качеството на здравната помощ, изготвяне
				и актуализация на правила за добра медицинска практика, изработване на ръководства за профилактика,
				диагностика и лечение на различни заболявания в общата медицинска практика, участие в дейността на
				Българско научно дружество по Обща медицина
			</p>
			<p>6.Участие в проекти, свързани с опазване здравето на населението, с медицински проучвания и дейности.</p>
			<p>
				7. Участие в изготвянето на НРД, други договори, закони или наредби, касаещи работата на ОПЛ в
				национален мащаб.
			</p>
			<p>8.Организиране на разрешени от закона протестни действия в защита интересите на членовете на ССОПЛ.</p>
			<p>
				9.Всички други средства, които не противоречат на законите в РБ, решенията на ОС и Устава на
				сдружението.
			</p>
			<p align="center">
				<strong>
					ГЛАВА II
					<br />
					СРОК, ЗА КОЙТО СЕ УЧРЕДЯВА СДРУЖЕНИЕТО
					<br />
				</strong>
			</p>
			<p>
				<strong>Чл.6</strong>
				Сдружението се учредява без срок.
			</p>
			<p align="center">
				<strong>
					ГЛАВА III
					<br />
					ПРЕДМЕТ НА ДЕЙНОСТ
					<br />
				</strong>
			</p>
			<p>
				<strong>Чл.7</strong>
				Да координира, подпомага, представлява и защитава професионалните и икономическите интереси на
				общопрактикуващите лекари на територията на обл. Стара Загора. Сдружението може да извършва допълнителна
				стопанска дейност, само ако същата е свързана с предмета на основната му дейност и при положение, че
				приходите от тази допълнителна дейност се използват изцяло за постигане на целите на сдружението, като:
			</p>
			<p>извършване на рекламна дейност;</p>
			<p>извършване на издателска дейност;</p>
			<p>извършване на научна дейност;</p>
			<p>организиране на конференции, семинари и лекции;</p>
			<p>наемане на обекти за постигане целта на сдружението;</p>
			<p>други дейности, непротиворечащи на законодателството в Р.България..</p>
			<p align="center">
				<strong>ГЛАВА ІV</strong>
			</p>
			<p align="center">
				<strong>
					СЪСТАВ И ОРГАНИ НА УПРАВЛЕНИЕ
					<br />
				</strong>
			</p>
			<p>
				<strong>Чл.8</strong>
				(1) Върховен орган на ССОПЛ е ОБЩОТО СЪБРАНИЕ /ОС/.
			</p>
			<p>(2) Управителен орган на ССОПЛ е УПРАВИТЕЛНИЯТ СЪВЕТ /УС/</p>
			<p>(3) Контролен орган е Контролната комисия /КК/.</p>
			<p>
				(4) Комисия по професионална етика е орган, който се занимава с професионално-медицински, морално-етични
				и деонтологични въпроси, свързани с упражняването на лекарската професия. /КПЕ/.
			</p>
			<p>
				(5) Регионални секции - секции на ССОПЛ /най-малко от 4 члена/, представлявани от секционен координатор,
				носещ гласовете им, или представлявани лично, при изрично заявено желание на събранията на регионалната
				секция.
			</p>
			<p>
				<strong>Чл.9</strong>
				Общото събрание се състои от членовете на сдружението.
			</p>
			<p>
				<strong>Чл.10</strong>
				ОБЩО СЪБРАНИЕ:
			</p>
			<p>(1) Общото събрание има следните правомощия:</p>
			<p>1. Изменя и допълва Устава;</p>
			<p>2. Приема отчета за дейността на УС;</p>
			<p>3. Приема други вътрешни актове;</p>
			<p>
				4. Избира и освобождава председателя на УС на ССОПЛ, Заместник-председател, секретар и членовете на
				Управителния съвет, както и избира трима резервни членове на УС;
			</p>
			<p>5. Избира и освобождава членовете на Контролната комисия и Комисията по професионална етика;</p>
			<p>6.Взема решения за преобразуване или прекратяване на сдружението;</p>
			<p>7. Приема бюджета на сдружението;</p>
			<p>
				8.Отменя решения на другите органи на сдружението, които противоречат на закона, Устава или други
				вътрешни актове, регламентиращи дейността на сдружението;
			</p>
			<p>9. Приема основните насоки,програма за дейността на сдружението;</p>
			<p>
				10. Определя размера на членския внос, както и други вноски, пряко свързани с предмета на дейност на
				ССОПЛ.
			</p>
			<p>11. Взема решение за участие на ССОПЛ в други организации.</p>
			<p>
				12. Избира делегати Общото събрание на РЛК - Стара Загора на квотен принцип в съответствие с Устава на
				Български Лекарски Съюз.
			</p>
			<p>13. Взема и други решения, предвидени в Устава.</p>
			<p>(2) Решенията на Общото събрание са задължителни за другите органи на сдружението.</p>
			<p>
				(3) Решенията на Общото събрание подлежат на съдебен контрол относно тяхната законосъобразност и
				съответствие с Устава.
			</p>
			<p>
				(4) Решенията на органите на сдружението, които са взети в противоречие със закона, Устава или предходно
				решение на ОС, могат да бъдат оспорвани пред ОС по искане на заинтересованите членове на сдружението или
				на негов орган, отправено в едномесечен срок от узнаването им, но не по-късно от една година от датата
				на вземането на решението.
			</p>
			<p>
				(5) Споровете по ал.4 могат да бъдат повдигани пред съда по регистрацията на сдружението от всеки член
				на сдружението или на негов орган, или от прокурора в едномесечен срок от узнаването им, но не по-късно
				от една година от датата на вземане на решението.
			</p>
			<p>
				<strong>Чл.11</strong>
				СВИКВАНЕ НА ОБЩОТО СЪБРАНИЕ:
			</p>
			<p>
				(1) ОС се свиква от УС по негова инициатива или по искане на една трета от членовете на сдружението. Ако
				в последния случай Управителния съвет на ССОПЛ в едномесечен срок не отправи писмена покана за свикване
				на Общото събрание, то се свиква от съда по седалището на сдружението по писмено искане на
				заинтересованите членове или натоварено от тях лице.
			</p>
			<p>
				(2) ОС се свиква на редовно заседание{" "}
				<strong>
					<u>ежегодно</u>.
				</strong>
			</p>
			<p>
				(3) Извънредно ОС се провежда по предложение на УС или по писмено искане на най-малко 1/3 от членовете
				на ССОПЛ. При искане за провеждане на извънредно Общо събрание УС на ССОПЛ го свиква най-късно в 40 -
				дневен срок от получаването на писменото искане, като се спазят условията визирани в ал.1 на настоящия
				член.
			</p>
			<p>
				(4) Поканата трябва да съдържа дневния ред, датата, часа и мястото на провеждането на Общото събрание и
				по чия инициатива се свиква.
			</p>
			<p>
				(5) Поканата се изнася в интернет-сайта на ССОПЛ
				<strong>
					<u>,или се изпраща по</u>
				</strong>
				<strong>
					<u> email </u>
				</strong>
				<strong>
					<u>,или чрез </u>
				</strong>
				<strong>
					<u>SMS</u>
				</strong>{" "}
				<strong>
					<u> </u>
				</strong>{" "}
				и се поставя на мястото за обявления в сградата, в която се намира управлението на сдружението,
				най-малко един месец преди насрочения ден.
			</p>
			<p>
				<strong>Чл.12</strong>
				КВОРУМ
			</p>
			<p>
				(1) Общото събрание е законно, ако присъстват повече от половината от <strong>членовете</strong>. При
				липса на кворум събранието се отлага за един час и се провежда на същото място и при същия дневен ред, и
				се счита за законно, независимо от броя на явилите се <strong>членове</strong>.
			</p>
			<p>
				(2) Мандатната комисия осъществява регистрацията на <strong>членовете</strong> и при откриване на
				заседанието на Общото събрание обявява наличието на кворум
			</p>
			<p>1. Състои от трима души – Председател и двама членове.</p>
			<p>
				2. Членовете на комисията регистрират участниците в Общото събрание и по време на заседанието изпълняват
				функции на преброители.
			</p>
			<p>
				<strong>Чл.13</strong>
				ГЛАСУВАНЕ:
			</p>
			<p>
				Всеки <strong>член</strong> има право на един глас. Гласуването е явно, освен ако мнозинството от{" "}
				<strong>членовете</strong> не установи друг ред.
			</p>
			<p>
				<strong>Чл.14</strong>
				Всички заседания на Общото събрание са открити.
			</p>
			<p>
				<strong>Чл.15</strong>
				Общото събрание се ръководи от Председателя на ССОПЛ, а в негово отсъствие от Заместник-председателя или
				от упълномощен от тях член на УС. Общото събрание избира протоколчик, който се подписва заедно с
				водещите събранието под протокола.
			</p>
			<p>
				<strong>Чл.16</strong>
				ВЗЕМАНЕ НА РЕШЕНИЯ:
			</p>
			<p>(1) Решенията на Общото събрание се вземат с обикновено мнозинство.</p>
			<p>
				(2) Решенията по чл.10, ал.1, т.1 и 6 се вземат с мнозинство от 2/3 от присъстващите{" "}
				<strong>членове</strong>.
			</p>
			<p>(3) По въпроси, които не са включени в обявения в поканата дневен ред, не може да се вземат решения.</p>
			<p>
				<strong>Чл.17</strong>
				УПРАВИТЕЛЕН СЪВЕТ
			</p>
			<p>
				(1)Управителният съвет /УС/ се състои от <strong>7</strong>{" "}
				<strong>
					<em>/седем/</em>
				</strong>
				<em> </em>души - Председател, Заместник-председател, Секретар и четири члена, като всички са
				общопрактикуващи лекари.
			</p>
			<p>(2) Членовете на Управителния съвет се избират:</p>
			<p>1. По вишегласие;</p>
			<p>
				2. За срок от{" "}
				<strong>
					<u>
						4 /четири<em>/</em> години
					</u>
				</strong>
				;
			</p>
			<p>
				3.
				<strong>
					<u>Член на УС, може да бъде избиран и за следващ мандат</u>
				</strong>
				.
			</p>
			<p>
				(3) Управителният съвет е длъжен в рамките на 30 дни преди изтичане на мандата му да свика Общото
				събрание чрез писмена покана за избор на нов Управителен съвет. Ако в този едномесечен срок УС не
				отправи такава покана към членовете на ССОПЛ, се спазват разпоредбите на чл.11, ал.(1) и ал.(3) от този
				Устав.
			</p>
			<p>
				<strong>Чл.18</strong>
				УПРАВИТЕЛНИЯТ СЪВЕТ ИМА СЛЕДНИТЕ ПРАВОМОЩИЯ:
			</p>
			<p>
				1. Представлява сдружението, както определя и обема на представителната власт на отделни негови членове;
			</p>
			<p>2. Осигурява изпълнението на решенията на Общото събрание;</p>
			<p>3. Разпорежда се с имуществото на сдружението при спазване изискванията на Устава.</p>
			<p>4. Подготвя и внася в Общото събрание проект за бюджет;</p>
			<p>5. Подготвя и внася в Общото събрание отчет за дейността на сдружението;</p>
			<p>
				6. Определя реда<strong>, </strong>ръководи и организира оперативно <strong> </strong>дейността на
				сдружението, включително и тази в обща полза, и носи отговорност за това;
			</p>
			<p>7. Определя адреса на управление на сдружението;</p>
			<p>
				8. Взема решения по всички въпроси, които по закон или съгласно устава не спадат в правата на друг
				орган;
			</p>
			<p>9. Изпълнява задълженията, предвидени в Устава;</p>
			<p>
				10. Свиква Общото събрание в предвидените от закона и Устава случаи, като подготвя и разгласява дневния
				ред;
			</p>
			<p>
				11.Приема нови и изключва<strong> </strong>членове, приема почетни членове, съхранява регистъра им,
				определя реда за съхранение на документацията на ССОПЛ;
			</p>
			<p>12.Наема външни физически и юридически лица, според възникналите потребности на сдружението;</p>
			<p>13. Определя лицата, които могат да бъдат командировани от името на ССОПЛ;</p>
			<p>14. Определя състава на Мандатната комисия за всяко Общо събрание;</p>
			<p>15. Определя временни комисии за подпомагане дейността на сдружението по текущо възникнали проблеми;</p>
			<p>16. Взема решение за откриване и закриване на клонове;</p>
			<p>17. Предлага пред ОС решение за участие на ССОПЛ в други организации;</p>
			<p>18. Изготвя и подлага на обсъждане пред ОС основните насоки и програма за дейността на сдружението;</p>
			<p>19. Изготвя и приема правилник за работата си;</p>
			<p>
				20. Взема решения по всички въпроси, които по закон или съгласно Устава не спадат в правата на друг
				орган на ССОПЛ;
			</p>
			<p>
				<strong>Чл.19</strong>
				ЗАСЕДАНИЯ НА УПРАВИТЕЛНИЯ СЪВЕТ
			</p>
			<p>
				(1) Заседанията на УС се свикват и ръководят от Председателя, а в негово отсъствие от
				Заместник-председателя или Секретаря. Председателят е длъжен да свика заседание на УС при писмено искане
				на половината плюс един от членовете му. Ако Председателят не свика заседание на УС съвет в едноседмичен
				срок, то може да се свика от всеки един от заинтересованите членове на Управителния съвет.
			</p>
			<p>
				(2) УС провежда редовни заседания най-малко веднъж на три месеца по предварително изготвен график.
				Редовните заседания винаги се съобщават на членовете на УС. За членовете на УС присъствието е
				задължително. Заседанията са открити, освен ако не е прието друго.
			</p>
			<p>
				(3) УС може да взема решение, ако на заседанието му присъстват повече от половината от неговите членове.
				За присъстващо се счита и лице, с което има двустранна телефонна, или друга връзка, гарантираща
				установяване на самоличността му и позволяваща участие в обсъждането и вземането на решения. Гласуването
				на този член се удостоверява в протокола с подписа на председателстващия и протоколчика и това изрично
				се отбелязва.
			</p>
			<p>(4) Решенията се вземат с обикновено мнозинство от присъстващите.</p>
			<p>(5) Всеки член на УС има право на един глас.</p>
			<p>
				(6) УС може да взема решения извън редовните заседания и неприсъствено /без провеждане на заседание/,
				когато протоколът за взетото решение се подпише впоследствие без забележки и възражения за това от
				всички негови членове.
			</p>
			<p>
				<strong>Чл.20</strong>
				ЧЛЕНСТВО В УПРАВИТЕЛНИЯ СЪВЕТ:
			</p>
			<p>(1) Членството в УС се прекратява при смърт, оставка или отстраняване.</p>
			<p>
				(2) Освободените места от починали, напуснали или отстранени членове на УС се попълват от резервите
				членове, избрани от Общото събрание, като новия член изпълнява задълженията си за остатъка от срока на
				мандата на УС.
			</p>
			<p>
				(3) При освобождаване мястото на Председателя на УС, то се заема от Зам.-председателя на сдружението или
				от друг член на УС, избран от УС, който изпълнява всички функции и правомощия, определени от Устава и
				решенията на Общото събрание за Председателя.
			</p>
			<p>(4) Отстраняване на член на УС може да стане в случаите на:</p>
			<p>1.грубо нарушение на Устава или решенията на Общото събрание;</p>
			<p>
				2.системно неизпълнение на задачите, поставени от УС и системно неоказване на помощ и съдействие за
				постигане целите на сдружението.
			</p>
			<p>
				(5) Решенията за отстраняване на член на УС се вземат от Общото събрание и се приемат с явно гласуване и
				обикновено мнозинство.
			</p>
			<p>
				<strong>Чл.21</strong>
				ПРЕДСЕДАТЕЛ НА УПРАВИТЕЛНИЯ СЪВЕТ
			</p>
			<p>
				(1) ССОПЛ се представлява пред всички трети лица от Председателя на УС. Заместник-председателят и
				Секретарят имат това право, след упълномощаване от Председателя, в негово отсъствие.
			</p>
			<p>
				(2) В случай, че при първото гласуване нито един от кандидатите не получи половината плюс един от
				гласовете, се провежда второ гласуване, в което се състезават двама кандидати получили най-много
				гласове, като за избран се счита кандидатът, получил повече гласове.
			</p>
			<p>
				(3) Председателят, Заместник председателят и Секретарят разпределят помежду си и между членовете на УС
				задълженията, поставени от Общото събрание, както и текущите такива.
			</p>
			<p>
				<strong>Чл.22</strong>
				РЕГИОНАЛНИ СЕКЦИИ :
			</p>
			<p>
				(1) Членовете на ССОПЛ могат да се обединяват в регионални секции на териториален принцип, съгласно
				административното деление на област Стара Загора.
			</p>
			<p>
				(2) Регионалната секция е структурна единица на ССОПЛ. Тя се ръководи и представлява от секционен
				координатор, избран измежду нейните членове
			</p>
			<p>(3) Регионалната секция води и съхранява актуален регистър на членовете си.</p>
			<p>
				<strong>Чл. 23</strong>
				КОНТРОЛЕН ОРГАН
			</p>
			<p>(1) Контролен орган на сдружението е Контролната комисия /КК/.</p>
			<p>
				(2)<strong> </strong>Общото събрание избира КК за срок от <strong>четири</strong> години.
			</p>
			<p>(3) КК се състои от трима души, избрани от ОС с явно гласуване и обикновено мнозинство.</p>
			<p>
				(4) КК избира измежду своите членове с обикновено мнозинство Председател, който свиква и ръководи
				нейните заседания, които са не по-малко от веднъж на 6 месеца.
			</p>
			<p>
				<strong>Чл.24</strong>
				ПРАВОМОЩИЯ НА КОНТРОЛНАТА КОМИСИЯ:
			</p>
			<p>(1) КК следи за:</p>
			<p>1.Правилното набиране и разходване на средствата от бюджета на сдружението;</p>
			<p>2.Съответствията на действията на УС с решенията на Общото събрание и Устава.</p>
			<p>(2) КК се отчита пред всяко Общото събрание.</p>
			<p>
				(3) Член на КК може да напусне доброволно или да бъде отстранен при условията за напускане и
				отстраняване за членове на УС.
			</p>
			<p>
				<strong>Чл. 25 </strong>
				КОМИСИЯ ПО ПРОФЕСИОНАЛНА ЕТИКА /КПЕ/
			</p>
			<p>
				(1)Общото събрание избира КПЕ за срок от <strong>четири</strong> години.
			</p>
			<p>
				(2) КПЕ на ССОПЛ се състои от председател и двама членове, избрани от Общото събрание с явно гласуване и
				обикновено мнозинство.
			</p>
			<p>(3) КПЕ избира от състава си с обикновено мнозинство Председател</p>
			<p>
				(4) Комисията се произнася по професионално-медицински, морално-етични и деонтологични въпроси, свързани
				с упражняването на лекарската професия.
			</p>
			<p>
				(5) КПЕ заседава при присъствие на повече от 2/3 от членовете си и взема решения с квалифицирано
				мнозинство 2/3 от присъстващите.
			</p>
			<p align="center">
				<br />
				<strong>
					ГЛАВА V
					<br />
					ЧЛЕНСТВО
					<br />
				</strong>
			</p>
			<p>
				<strong>Чл.26 </strong>
				(1) Лекарите, които желаят да членуват в ССОПЛ е необходимо:
			</p>
			<p>
				1. Да отговарят на изискванията за лекарска правоспособност в Р.България, да практикуват обща медицина
				или да са действащи преподаватели по обща медицина.
			</p>
			<p>2. Да са съгласни с разпоредбите на настоящия Устав.</p>
			<p>(2) Членството в ССОПЛ възниква след решение на УС и вписване в регистъра на ССОПЛ</p>
			<p>
				(3) Вписването се извършва след подаване на заявление, в което се посочват: трите имена, ЕГН, УИН,
				регистрационен номер на практиката /ако има такава/, адрес по местоживеене и месторабота, телефон за
				връзка /стационарен и мобилен/, е- mail. Заявлението са внася в УС на ССОПЛ.
			</p>
			<p>
				(4) В случай на отказ от приемане, УС на ССОПЛ следва да уведоми писмено, заинтересованата страна за
				мотивите на отказа си в едномесечен срок.
			</p>
			<p>
				<strong>Чл.27 </strong>
				ПРАВА И ЗАДЪЛЖЕНИЯ НА ЧЛЕНОВЕТЕ НА ССОПЛ:
			</p>
			<p>(1) Всеки лекар, член на сдружението, има следните права:</p>
			<p>
				1. Да избира и бъде избиран в органите на управление на ССОПЛ, с изключение на предвидените в Устава
				ограничения;
			</p>
			<p>2. Да изразява свободно мнението си за дейността на ССОПЛ;</p>
			<p>3. Да ползва защита на правата и интересите си;</p>
			<p>4. Да бъде информиран от органите на управление за дейността на ССОПЛ, съгласно настоящия Устав;</p>
			<p>5. Да участва във формите на професионална квалификация, организирани от сдружението.</p>
			<p>(2) Всеки лекар, член на сдружението има следните задължения:</p>
			<p>1.Да спазва Устава на ССОПЛ;</p>
			<p>2.Да спазва утвърдените от БЛС принципи на медицинска етика;</p>
			<p>3.Да не използва членството си в ССОПЛ за постигане на лични и користни цели;</p>
			<p>4.Да изпълнява решенията на Общото събрание;</p>
			<p>5.Да плаща редовно членски си внос, както и други парични вноски, утвърдени от ОС;</p>
			<p>
				6.Членският внос към ССОПЛ се превежда по банковата сметка на сдружението или се заплаща в брой на
				счетоводителя на ССОПЛ или на друго лице, определено от УС.
			</p>
			<p>
				<strong>Чл. 28 </strong>
				ПРЕКРАТЯВАНЕ НА ЧЛЕНСТВОТО
			</p>
			<p>
				(1)<strong> </strong>Индивидуалното членството се прекратява:
			</p>
			<p>1. с едностранно писмено волеизявление на индивидуален член до УС на ССОПЛ;</p>
			<p>2. при смърт или поставяне под пълно запрещение на член на ССОПЛ;</p>
			<p>3. с изключване на члена на ССОПЛ;</p>
			<p>4. с прекратяване на ССОПЛ;</p>
			<p>5. при отпадане.</p>
			<p>
				(2) Решението за изключване се взема от УС на ССОПЛ с обикновено мнозинство при кворум 50 % плюс 1 глас.
			</p>
			<p>
				(3) Решението за изключване се съобщава писмено на лицето от Председателя на УС на ССОПЛ в 14-дневен
				срок.
			</p>
			<p>
				(4) Решението за изключване може да се обжалва пред Общото събрание в едномесечен срок от узнаването му,
				но не по-късно от 1 година от датата на вземане на решението.
			</p>
			<p>
				(5) Отпадане на индивидуално членство е налице, при системно невнасяне на членски внос за три и повече
				месеца, липса на интерес спрямо целите и неучастие в дейността на ССОПЛ, злоупотреба с името на
				сдружението и подронване на авторитета му. Отпадането се констатира от УС по финансово-отчетните
				документи и становището на КК или с протоколи от проведени мероприятия и други документи. Отпадането на
				членство се оформя с надлежно решение, с което се прекратява членството.
			</p>
			<p>
				(6) Членовете с виновно поведение заплащат членски внос до датата на взетото решение за прекратяване на
				членството им поради изключване или отпадане на членство.
			</p>
			<p>
				(7) При прекратяване на членственото правоотношение, направените членски вноски не се възстановяват от
				сСОПЛ, независимо от основанието за прекратяване.
			</p>
			<p align="center">
				<strong>
					ГЛАВА VI
					<br />
					ПРЕКРАТЯВАНЕ
				</strong>
			</p>
			<p>
				<strong>Чл.29</strong>
				(1) ССОПЛ се прекратява:
			</p>
			<p>1. С решение на върховния си орган;</p>
			<p>2. С решение на Окръжния съд по седалището на юридическото лице с нестопанска цел, когато:</p>
			<p>а/ не е учредено по законен ред;</p>
			<p>б/ извършва дейност, която противоречи на закона или е противна на обществения ред или добрите нрави;</p>
			<p>в/ е обявено в несъстоятелност.</p>
			<p>(2) Решението по ал.1 т. 2 се постановява по иск на всеки заинтересован или прокурора.</p>
			<p>
				(3) Съдът може да даде подходящ срок за отстраняване на основанието за прекратяване и неговите
				последици.
			</p>
			<p>(4) В случаите на ал. 2 прекратяването се вписва служебно и съдът назначава ликвидатор.</p>
			<p align="center">
				<br />
				<strong>
					ГЛАВА VII
					<br />
					ЛИКВИДАЦИЯ
				</strong>
			</p>
			<p>
				<strong>Чл.30</strong>
				(1) При прекратяване на сдружението се назначава ликвидатор.
			</p>
			<p>(2) Ликвидацията се извършва от УС или от определен от него орган.</p>
			<p>
				(3) Ако ликвидатор не е определен по реда на ал.2, както и в случая на чл.32 ал.1 т.2, той се определя
				от Окръжния съд по седалището на сдружението.
			</p>
			<p>
				(4) Относно неплатежеспособността, съответно несъстоятелността, редът за ликвидация и правомощията на
				ликвидатора се прилагат съответно разпоредбите на Търговския закон.
			</p>
			<p>
				(5) При положение, че след приключване на ликвидацията и удовлетворяване на кредиторите има останало
				имущество, решението за разпределяне на това имущество се взема от Общото събрание.
			</p>
			<p align="center">
				<br />
				<strong>
					ГЛАВА VIII
					<br />
					ФИНАНСОВА ИЗДРЪЖКА И СОБСТВЕНОСТ
				</strong>
			</p>
			<p>
				<strong>Чл.31 </strong>
				Средствата за финансовата издръжка на ССОПЛ се набират от следните източници:
			</p>
			<p>1. Постъпления от членски внос и други парични вноски, утвърдени от Общото събрание.</p>
			<p>
				2. Постъпления от рекламна дейност, издателска дейност, организиране на конференции, семинари, лекции,
				научни изследвания и др.
			</p>
			<p>3. Дарения, завещания, спонсорство от трети лица в страната и в чужбина и др.;</p>
			<p>
				4. Стопанисване на движимо и недвижимо имущество на ССОПЛ, за осъществяване на целите, за които е
				създадено.
			</p>
			<p align="center">
				<strong>ГЛАВА ІХ ДРУГИ РАЗПОРЕДБИ</strong>
			</p>
			<p>
				<strong>Чл.32 </strong>
				Всяко писмено изявление от името на сдружението трябва да съдържа наименованието му, изписано съгласно
				чл.1 от Устава, както и данни за контакти : адрес за кореспонденция, телефон, факс, GSM, утвърдения
				e-mail и адрес на електронния сайт на сдружението
			</p>
			<p>
				<strong>Чл.33</strong>
				Всички въпроси, които не са уредени с настоящия Устав, се решават съгласно разпоредбите на Закона за
				юридическите лица с нестопанска цел и съобразно действащото в Република България законодателство.
			</p>
			<p>
				<strong>Чл.34 </strong>
				Учредените регионални секции - членове на ССОПЛ ползват всички права и спазват всички задължения на този
				Устав.
			</p>
			<p>
				Старозагорското сдружение на общопрактикуващите лекари (ССОПЛ) е неправителствено, доброволно,
				независимо, нерелигиозно и неполитическо сдружение с нестопанска цел на физически и/или юридически лица
				- български правоспособни лекари, лекари практикуващи Обща медицина и преподаватели по Обща медицина.
				ССОПЛ осъществява цялостната си дейност въз основа на своя Устав, решенията на своето Общото събрание и
				действащите закони.
			</p>
			<p>
				Настоящият Устав е приет на Учредително Общо събрание на 17.12.2008 год ,изменен и допълнен с Решение на
				Общо събрание на ССОПЛ от 25.05.2017год. и 28.11.2019год.
			</p>
		</div>
	);
}
