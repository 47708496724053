import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Alert } from "react-bootstrap";

import * as usefulLinksService from "../../../../services/usefulLinksService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";
import * as filestack from "filestack-js";

import "./EditUsefulLink.css";

const client = filestack.init("AI1Fw5JZR7atkwYVFDSSbz");

export default function EditUsefulLink() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [errors, setErrors] = useState({
		title: "",
		link: "",
	});
	const [linkInfo, setLinkInfo] = useState({
		title: "",
		link: "",
		logo: "",
	});

	useEffect(() => {
		usefulLinksService
			.getSingleLink(id)
			.then((linkData) => {
				setLinkInfo({
					title: linkData.title,
					link: linkData.link,
					logo: linkData.logo,
				});
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}, []);

	const editLinkCancelHandler = () => {
		navigate("/admin/useful-links");
	};

	const editLinkSubmitHandler = (e) => {
		e.preventDefault();

		if (!linkInfo.title || !linkInfo.link || !linkInfo.logo) {
			return notificationService.warning("Всички полета са задължителни!");
		}

		if (isFilePicked) {
			client.upload(selectedFile.data).then((data) => {
				usefulLinksService
					.editLink(id, {
						logo: data.url,
						title: linkInfo.title,
						link: linkInfo.link,
					})
					.then((data) => {
						notificationService.success("Успешно променена връзка");
						navigate("/admin/useful-links");
					})
					.catch((err) => {
						errorHandlingService.messageHandler(err);
					})
					.catch((err) => console.log(err));
			});
		} else {
			usefulLinksService
				.editLink(id, {
					logo: linkInfo.logo,
					title: linkInfo.title,
					link: linkInfo.link,
				})
				.then((data) => {
					notificationService.success("Успешно променена връзка");
					navigate("/admin/useful-links");
				})
				.catch((err) => {
					errorHandlingService.messageHandler(err);
				});
		}
	};

	const imgChangeHandler = (e) => {
		const img = {
			preview: URL.createObjectURL(e.target.files[0]),
			data: e.target.files[0],
		};
		setSelectedFile(img);
		setLinkInfo((state) => ({
			...state,
			imgUrl: img.data.name,
		}));
		setIsFilePicked(true);
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	return (
		<div className="edit-link-page-wrapper">
			<div className="edit-link-content-wrapper">
				<form onSubmit={editLinkSubmitHandler}>
					<span className="edit-link-current-logo">Текущо лого: {linkInfo.logo}</span>
					<span className="btn btn-primary btn-file">
						Избери лого...
						<input
							className="create-link-file-upload-input"
							type="file"
							name="file"
							onChange={imgChangeHandler}
						/>
					</span>
					<label htmlFor="title">Заглавие</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								title: "",
							}));
							setLinkInfo((state) => ({
								...state,
								title: e.target.value,
							}));
						}}
						className="edit-link-form-input"
						type="text"
						name="title"
						value={linkInfo.title}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.title.length > 0}>
						{errors.title}
					</Alert>
					<label htmlFor="link">Линк</label>
					<input
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								link: "",
							}));
							setLinkInfo((state) => ({
								...state,
								link: e.target.value,
							}));
						}}
						className="edit-link-form-input"
						type="text"
						name="link"
						value={linkInfo.link}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.link.length > 0}>
						{errors.link}
					</Alert>
					<div className="edit-link-button-wrapper">
						<input className="edit-link-button edit-link-edit-button" type="submit" value="Промени" />
						<Link
							onClick={editLinkCancelHandler}
							className="edit-link-button edit-link-deletes-button"
							to={"/admin/useful-links"}
						>
							Отказ
						</Link>
					</div>
				</form>
			</div>
		</div>
	);
}
