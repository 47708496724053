import * as notificationService from "./notificationService.js";

export const messageHandler = (err) => {
	err = err.toString();
	if (err.includes("validation")) {
		let errors = err.split(", ");
		errors.forEach((error) => {
			notificationService.fail(error.split(":").pop());
		});
	} else if (err.includes("fetch")) {
		notificationService.fail("Сървърът е недостъпеn. Опитайте по-късно");
	} else {
		notificationService.fail(err);
	}
};
