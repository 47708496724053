import Swal from "sweetalert2";
import "animate.css";

export const success = (message) => {
	return Swal.fire({
		position: "top-end",
		icon: "success",
		title: message,
		showConfirmButton: false,
		timer: 1500,
	});
};

export const fail = (message) => {
	return Swal.fire({
		position: "top-end",
		icon: "error",
		title: message,
		showConfirmButton: false,
		timer: 1500,
	});
};

export const warning = (message) => {
	return Swal.fire({
		position: "top-end",
		icon: "warning",
		title: message,
		showConfirmButton: false,
		timer: 1500,
	});
};

export const provideData = (message) => {
	return Swal.fire({
		title: message,
		showClass: {
			popup: "animate__animated animate__fadeInDown",
		},
		hideClass: {
			popup: "animate__animated animate__fadeOutUp",
		},
	});
};

export const dataWithImg = (data) => {
	Swal.fire({
		title: `${data.firstName} ${data.lastName}`,
		text: data.description,
		imageUrl: data.img,
		imageWidth: 300,
		imageHeight: 300,
		customClass: {
			image: 'doctor-details-image'
		},
		imageAlt: "Custom image",
	});
}


