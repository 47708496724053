import { Tabs, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import * as doctorService from "../../../services/doctorService.js";
import * as errorHandlingService from "../../../services/errorHandlingSerivce.js";

import CreateDoctor from "./CreateDoctor/CreateDoctor.js";

import "./Doctors.css";
import Doctor from "./Doctor/Doctor.js";
import Members from "./Members/Members.js";
import Loader from "../../Shared/Loader/Loader.js";

export default function Doctors() {
	const [key, setKey] = useState("doc-list");
	const [doctors, setDoctors] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	function updateDoctorsState() {
		setIsLoading(true);
		doctorService
			.getAllDoctors()
			.then((doctorsData) => {
				setDoctors(doctorsData);
				setIsLoading(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}

	useEffect(() => {
		updateDoctorsState();
	}, []);

	const handleCreate = () => {
		updateDoctorsState();
		setKey("doc-list");
	};

	const handleDelete = () => {
		updateDoctorsState();
		setKey("doc-list");
	};

	const hasDoctors = (
		<table>
			<thead>
				<tr>
					<th>Име</th>
					<th>Фамилия</th>
					<th >Специалност</th>
					<th >Описание</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{doctors.map((doctor) => (
					<Doctor key={doctor._id} doctor={doctor} handleDelete={handleDelete} />
				))}
			</tbody>
		</table>
	);

	const noDoctors = <h3 className="doc-list-no-docs">За момента няма членове на управителния съвет</h3>;

	return (
		<div className="doctors-wrapper">
			<Tabs
				id="controlled-tab-doctors"
				activeKey={key}
				onSelect={(k) => {
					setKey(k);
				}}
				className="mb-3 admin-doctors-tabs"
			>
				<Tab eventKey="doc-list" title="Управителен съвет">
					<div className="doc-tab">
						{isLoading ? <Loader /> : doctors.length > 0 ? hasDoctors : noDoctors}
					</div>
				</Tab>
				<Tab eventKey="doc-create" title="Нов член на управителен съвет">
					<CreateDoctor handleCreate={handleCreate} />
				</Tab>
				<Tab eventKey="members-list" title="Членове">
					<Members />
				</Tab>
			</Tabs>
		</div>
	);
}
