import baseUrl from "../constants.js";

export const create = async (title, file_type, url) => {
	let res = await fetch(`${baseUrl}/news`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			title: title,
			file_type: file_type,
			url: url,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getAllNews = async () => {
	let res = await fetch(`${baseUrl}/news`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getSingleNew = (id) => {
	return fetch(`${baseUrl}/news/${id}`).then((res) => res.json());
};

export const getNewsPaginated = async (startIndex, limit) => {
	const params = new URLSearchParams();
	params.append("startIndex", startIndex);
	params.append("limit", limit);
	let res = await fetch(`${baseUrl}/news/list?${params.toString()}`);
	
	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const editNew = async (id, newData) => {
	let res = await fetch(`${baseUrl}/news/${id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(newData),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const deleteNew = async (id) => {
	let res = await fetch(`${baseUrl}/news/delete`, {
		method: "DELETE",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			id: id,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};
