import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import * as usefulLinksService from "../../../services/usefulLinksService.js";
import * as errorHandlingService from "../../../services/errorHandlingSerivce.js";

import ListPagination from "../../Shared/ListPagination/ListPagination.js";
import Loader from "../../Shared/Loader/Loader.js";
import CreateUsefulLink from "./CreateUsefulLink/CreateUsefulLink.js";

import "./UsefulLinks.css";
import SingleLink from "./SingleLink/SingleLink.js";

export default function UsefulLinks() {
	const [key, setKey] = useState("links-list");
	const [links, setLinks] = useState([]);
	const [linksCount, setLinksCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	function updateLinksState() {
		setIsLoading(true);
		usefulLinksService
			.getLinksPaginated(0, 10)
			.then((linksData) => {
				setLinks(linksData.links);
				setLinksCount(linksData.totalResults);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				errorHandlingService.messageHandler(err);
			});
	}

	useEffect(() => {
		updateLinksState();
	}, []);

	const handleCreate = () => {
		updateLinksState();
		setKey("links-list");
	};

	const handleDelete = () => {
		updateLinksState();
		setKey("links-list");
	};

	const handleChangeOrder = (direction, oldOrder) => {
		setIsLoading(true);
		usefulLinksService
			.reArrangeLinks(direction, oldOrder)
			.then((data) => {
				setIsLoading(false);
				updateLinksState();
			})
			.catch((err) => {
				setIsLoading(false);
				errorHandlingService.messageHandler(err);
			});
	};

	const handleDeleteReorder = (order) => {
		setIsLoading(true);
		usefulLinksService
			.deleteReorder(order)
			.then((data) => {
				setIsLoading(false);
				updateLinksState();
			})
			.catch((err) => {
				setIsLoading(false);
				errorHandlingService.messageHandler(err);
			});
	};

	const currentPage = (value) => {
		setIsLoading(true);
		usefulLinksService
			.getLinksPaginated((value - 1) * 10, 10)
			.then((linksData) => {
				setLinks(linksData.links);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				errorHandlingService.messageHandler(err);
			});
	};

	const hasLinks = (
		<table>
			<thead>
				<tr>
					<th></th>
					<th>Лого</th>
					<th>Заглавие</th>
					<th>Линк</th>
					<th></th>
				</tr>
			</thead>
			<tbody className="useful-links-table-body">
				{links.map((link) => (
					<SingleLink
						key={link._id}
						link={link}
						handleDelete={handleDelete}
						linksCount={linksCount}
						handleChangeOrder={handleChangeOrder}
						handleDeleteReorder={handleDeleteReorder}
					/>
				))}
			</tbody>
		</table>
	);

	const noLinks = <h3 className="admin-links-list-no-links">За момента няма полезни линкове</h3>;

	return (
		<div className="admin-links-wrapper">
			<Tabs
				id="controlled-tab-links"
				activeKey={key}
				onSelect={(k) => {
					setKey(k);
				}}
				className="mb-3"
			>
				<Tab eventKey="links-list" title="Списък">
					<div className="admin-links-tab-wrapper">
						<div className="admin-links-pagination">
							<ListPagination count={linksCount} currentPage={currentPage} />
						</div>
						{isLoading ? <Loader className="admin-links-loader" /> : links.length > 0 ? hasLinks : noLinks}
					</div>
				</Tab>
				<Tab eventKey="links-create" title="Нова връзка">
					{<CreateUsefulLink handleCreate={handleCreate} />}
				</Tab>
			</Tabs>
		</div>
	);
}
