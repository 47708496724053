import { memo } from "react";
import { NavLink } from "react-router-dom";
import "./Hero.css";

function Hero() {
	return (
		<div className="hero-wrapper">
			<div className="hero-info">
				<h1>
					<i className="fa-solid fa-heart-pulse"></i> С грижа за интересите на лекарите в първичната
					извънболнична медицинска помощ.
				</h1>
				{/* <p>С грижа за интересите на лекарите в първичната извънболнична медицинска помощ.</p> */}
				<NavLink className="hero-btn" to="/contacts">
					Свържи се с нас
				</NavLink>
			</div>
			<div className="hero-img">
				<img src="/images/male-doctor3.png" alt="" />
				<div className="hero-circle"></div>
			</div>
		</div>
	);
}

export default memo(Hero);
