import "./ContactForm.css";
import React, { useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

import * as notificationService from "../../../services/notificationService.js";
import { useNavigate } from "react-router-dom";

export default function ContactForm() {
	const [formDetails, setFormDetails] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [errors, setErrors] = useState({
		name: "",
		email: "",
		message: "",
	});
	const navigate = useNavigate();
	const form = useRef();

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	const onMailSendHandler = (e) => {
		e.preventDefault();

		if (!formDetails.name || !formDetails.email || !formDetails.message) {
			return notificationService.warning("Всички полета са задължителни!");
		}

		emailjs
			.sendForm("service_y4car4m", "template_e9xaovu", form.current, "N5JDVrHyLj14Tnpma")
			.then(() => {
				notificationService.success("Успешно изпратено съобщениие");
				navigate("/thankyou");
			})
			.catch((err) => notificationService.fail("Грешка: " + err));
	};

	return (
		<div className="contact-form-page-wrapper">
			<div className="contact-info-wrapper">
				<h2>Контакти</h2>
				<p>
					<i className="fa-solid fa-location-dot"></i> Старозагорско сдружение на общопрактикуващите лекари
					(ССОПЛ)
				</p>
				<p>
					<i className="fa-solid fa-phone"></i> Телефон:{" "}
					<a href="tel:+359887609034" title="Call +359887609034">
						0887609034
					</a>
				</p>
				<p>
					<i className="fa-solid fa-envelope"></i> E-mail: contacts@ssopl.org
				</p>
			</div>
			<div className="contact-form-wrapper">
				<h2>Форма за обратна връзка</h2>
				<form ref={form} className="contact-form" method="POST">
					<label htmlFor="name">Име</label>
					<input
						type="text"
						name="name"
						placeholder="Иван"
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								name: "",
							}));
							setFormDetails((state) => ({
								...state,
								name: e.target.value,
							}));
						}}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.name.length > 0}>
						{errors.name}
					</Alert>
					<label htmlFor="email">Email</label>
					<input
						type="email"
						name="email"
						placeholder="ivan@abv.bg"
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								email: "",
							}));
							setFormDetails((state) => ({
								...state,
								email: e.target.value,
							}));
						}}
						onBlur={onBlurHandler}
					/>
					<Alert className="contact-form-alert" variant="danger" show={errors.email.length > 0}>
						{errors.email}
					</Alert>
					<label htmlFor="message">Съобщение</label>
					<textarea
						className="contact-from-textarea"
						name="message"
						rows={10}
						onChange={(e) => {
							setErrors((state) => ({
								...state,
								message: "",
							}));
							setFormDetails((state) => ({
								...state,
								message: e.target.value,
							}));
						}}
						onBlur={onBlurHandler}
					></textarea>
					<Alert className="contact-form-alert" variant="danger" show={errors.message.length > 0}>
						{errors.email}
					</Alert>
					<ReCAPTCHA sitekey="6LdY7d8iAAAAAEy9pYIvkadFyj_6wY-AiFrldI-i" />
					<input
						className="contact-form-submit-button"
						type="submit"
						value="Изпрати"
						onClick={onMailSendHandler}
					/>
				</form>
			</div>
		</div>
	);
}
