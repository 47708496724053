import { Link, NavLink, useNavigate } from "react-router-dom";
import { Dropdown, DropdownButton, NavItem } from "react-bootstrap";

import "./Header.css";

export default function Header() {
	const navigate = useNavigate();

	let activeNavStyle = {
		borderBottom: "3px solid rgb(172,213,142)",
		color: "rgb(172,213,142)",
	};

	const logoClickHandler = () => {
		navigate("/");
	};

	return (
		<header className="header-wrapper">
			<div className="header-logo" onClick={logoClickHandler}>
				<img src="/images/logos/ССОПЛ_Blue.png" alt="" />
				<p>Старозагорско сдружение на общопрактикуващите лекари</p>
			</div>
			<nav className="header-nav">
				<ul className="main-nav">
					<li className="home-nav-button">
						<NavLink
							className="nav-button"
							to="/"
							style={({ isActive }) => (isActive ? activeNavStyle : { borderBottom: "", color: "" })}
						>
							Начало
						</NavLink>
					</li>
					<li>
						<NavLink
							className="nav-button"
							to="/news"
							style={({ isActive }) => (isActive ? activeNavStyle : { borderBottom: "", color: "" })}
						>
							Новини
						</NavLink>
					</li>
					<li>
						<DropdownButton className="nav-button" id="dropdown-item-button" title="За нас">
							<Dropdown.Item as={Link} to="/members">
								Членове на сдружението
							</Dropdown.Item>
							<Dropdown.Item as={Link} to="/apply">
								Заявление за членство
							</Dropdown.Item>
						</DropdownButton>
					</li>
					<li>
						<NavLink
							className="nav-button"
							to="/legislation"
							style={({ isActive }) => (isActive ? activeNavStyle : { borderBottom: "", color: "" })}
						>
							Нормативна уредба
						</NavLink>
					</li>
					<li>
						<NavLink
							className="nav-button"
							to="/forum"
							style={({ isActive }) => (isActive ? activeNavStyle : { borderBottom: "", color: "" })}
						>
							Форум
						</NavLink>
					</li>
					<li>
						<DropdownButton className="nav-button" id="dropdown-item-button" title="Полезно">
							<Dropdown.Item as={Link} to="/links">
								Полезни връзки
							</Dropdown.Item>
							<Dropdown.Item as={Link} to="/practice">
								Акредитирани практики
							</Dropdown.Item>
							<Dropdown.Item as={Link} to="/statute">
								Устав
							</Dropdown.Item>
							<Dropdown.Item as={Link} to="/check">
								Проверка на задължения
							</Dropdown.Item>
						</DropdownButton>
					</li>
					<li>
						<NavLink
							className="nav-button"
							to="/contacts"
							style={({ isActive }) => (isActive ? activeNavStyle : { borderBottom: "", color: "" })}
						>
							Контакти
						</NavLink>
					</li>
				</ul>
			</nav>
			<Dropdown as={NavItem} className="hidden-dropdown ">
				<Dropdown.Toggle className="drop-down-button">
					<i className="fa-solid fa-bars"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu">
					<Dropdown.Item as={Link} to="/">
						Начало
					</Dropdown.Item>

					<Dropdown.Item as={Link} to="/news">
						Новини
					</Dropdown.Item>

					<Dropdown.Item as={Link} to="/members">
						Членове на сдружението
					</Dropdown.Item>
					<Dropdown.Item as={Link} to="/apply">
						Заявление за членство
					</Dropdown.Item>
					<Dropdown.Item as={Link} to="/legislation">
						Нормативна уредба
					</Dropdown.Item>
					<Dropdown.Item as={Link} to="/forum">
						Форум
					</Dropdown.Item>
					<Dropdown.Item as={Link} to="/links">
						Полезни връзки
					</Dropdown.Item>
					<Dropdown.Item as={Link} to="/practice">
						Акредитирани практики
					</Dropdown.Item>
					<Dropdown.Item as={Link} to="/statute">
						Устав
					</Dropdown.Item>
					<Dropdown.Item as={Link} to="/check">
						Проверка на задължения
					</Dropdown.Item>
					<Dropdown.Item as={Link} to="/contacts">
						Контакти
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<div className="header-logo right-logo" onClick={logoClickHandler}>
				<p>Член на Националното сдружение на общопрактикуващите лекари</p>
				<img src="/images/logos/nsopl.jpg" alt="" />
			</div>
		</header>
	);
}
