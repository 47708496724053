import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Alert } from "react-bootstrap";

import * as doctorService from "../../../../services/doctorService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";
import * as filestack from "filestack-js";

import "./EditDoctor.css";
import Loader from "../../../Shared/Loader/Loader.js";

const client = filestack.init("AI1Fw5JZR7atkwYVFDSSbz");

export default function EditDoctor() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [doctorInfo, setDoctorInfo] = useState({
		firstName: "",
		lastName: "",
		specialty: "",
		description: "",
		imgUrl: "",
	});
	const [errors, setErrors] = useState({
		firstName: "",
		lastName: "",
		specialty: "",
		description: "",
	});
	useEffect(() => {
		doctorService
			.getSingleDoctor(id)
			.then((doctorData) => {
				setIsLoading(false);
				setDoctorInfo({
					firstName: doctorData.firstName,
					lastName: doctorData.lastName,
					specialty: doctorData.specialty,
					description: doctorData.description,
					imgUrl: doctorData.imgUrl,
				});
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}, []);

	const editDoctorCancelHandler = () => {
		navigate("/admin/docs");
	};

	const editDoctorSubmitHandler = (e) => {
		e.preventDefault();

		if (!doctorInfo.firstName || !doctorInfo.lastName || !doctorInfo.specialty || !doctorInfo.description) {
			return notificationService.warning("Всички полета са задължителни!");
		}

		if (isFilePicked) {
			setIsLoading(true);
			client.upload(selectedFile.data).then((data) => {
				doctorService
					.editDoctor(id, {
						firstName: doctorInfo.firstName,
						lastName: doctorInfo.lastName,
						specialty: doctorInfo.specialty,
						description: doctorInfo.description,
						imgUrl: data.url,
					})
					.then((data) => {
						setIsLoading(false);
						notificationService.success("Успешно променен член");
						navigate("/admin/docs");
					})
					.catch((err) => {
						errorHandlingService.messageHandler(err);
						setIsLoading(false);
					})
					.catch((err) => console.log(err));
			});
		} else {
			setIsLoading(true);
			doctorService
				.editDoctor(id, {
					firstName: doctorInfo.firstName,
					lastName: doctorInfo.lastName,
					specialty: doctorInfo.specialty,
					description: doctorInfo.description,
					imgUrl: doctorInfo.imgUrl,
				})
				.then((data) => {
					setIsLoading(false);
					notificationService.success("Успешно променен член");
					navigate("/admin/docs");
				})
				.catch((err) => {
					errorHandlingService.messageHandler(err);
					setIsLoading(false);
				});
		}

		
	};

	const imgChangeHandler = (e) => {
		const img = {
			preview: URL.createObjectURL(e.target.files[0]),
			data: e.target.files[0],
		};
		setSelectedFile(img);
		setDoctorInfo((state) => ({
			...state,
			imgUrl: img.data.name,
		}));
		setIsFilePicked(true);
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	return (
		<div className="edit-doctor-page-wrapper">
			<div className="edit-doctor-content-wrapper">
				{isLoading ? (
					<Loader />
				) : (
					<form onSubmit={editDoctorSubmitHandler}>
						<label htmlFor="firstName">Име</label>
						<input
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									firstName: "",
								}));
								setDoctorInfo((state) => ({
									...state,
									firstName: e.target.value,
								}));
							}}
							className="edit-doctor-form-input"
							type="text"
							name="firstName"
							value={doctorInfo.firstName}
							onBlur={onBlurHandler}
						/>
						<Alert className="contact-form-alert" variant="danger" show={errors.firstName.length > 0}>
							{errors.firstName}
						</Alert>
						<label htmlFor="lastName">Фамилия</label>
						<input
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									lastName: "",
								}));
								setDoctorInfo((state) => ({
									...state,
									lastName: e.target.value,
								}));
							}}
							className="edit-doctor-form-input"
							type="text"
							name="lastName"
							value={doctorInfo.lastName}
							onBlur={onBlurHandler}
						/>
						<Alert className="contact-form-alert" variant="danger" show={errors.lastName.length > 0}>
							{errors.lastName}
						</Alert>
						<label htmlFor="specialty">Специалност</label>
						<input
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									specialty: "",
								}));
								setDoctorInfo((state) => ({
									...state,
									specialty: e.target.value,
								}));
							}}
							className="edit-doctor-form-input"
							type="text"
							name="specialty"
							value={doctorInfo.specialty}
							onBlur={onBlurHandler}
						/>
						<Alert className="contact-form-alert" variant="danger" show={errors.specialty.length > 0}>
							{errors.specialty}
						</Alert>
						<span className="edit-doctor-selected-image">Текуща снимка: {doctorInfo.imgUrl}</span>
						<span className="btn btn-primary btn-file">
							Избери снимка...
							<input
								className="create-doctor-file-upload-input"
								type="file"
								name="file"
								onChange={imgChangeHandler}
							/>
						</span>

						<label htmlFor="description">Описание</label>
						<textarea
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									description: "",
								}));
								setDoctorInfo((state) => ({
									...state,
									description: e.target.value,
								}));
							}}
							className="edit-doctor-form-input"
							rows="20"
							cols=""
							name="description"
							value={doctorInfo.description}
							onBlur={onBlurHandler}
						/>
						<Alert className="contact-form-alert" variant="danger" show={errors.description.length > 0}>
							{errors.description}
						</Alert>
						<div className="edit-doctor-button-wrapper">
							<input
								className="edit-doctor-button edit-doctor-edit-button"
								type="submit"
								value="Промени"
							/>
							<Link
								onClick={editDoctorCancelHandler}
								className="edit-doctor-button edit-doctor-deletes-button"
								to={"/admin/docs"}
							>
								Отказ
							</Link>
						</div>
					</form>
				)}
			</div>
		</div>
	);
}
