import { NavLink, Outlet } from "react-router-dom";

import "./Admin.css";

export default function Admin() {
	const activeAdminNavStyle = {
		color: "rgb(172,213,142)",
	};

	return (
		<div className="admin-panel-wrapper">
			<div className="admin-nav">
				<h2 className="admin-heading">Админ Панел</h2>
				<ul>
					<li>
						<NavLink
							className="admin-nav-button"
							to="/admin/news"
							style={({ isActive }) => (isActive ? activeAdminNavStyle : { borderBottom: "", color: "" })}
						>
							Новини
						</NavLink>
					</li>
					<li>
						<NavLink
							className="admin-nav-button"
							to="/admin/docs"
							style={({ isActive }) => (isActive ? activeAdminNavStyle : { borderBottom: "", color: "" })}
						>
							Членове
						</NavLink>
					</li>
					<li>
						<NavLink
							className="admin-nav-button"
							to="/admin/legal-regulation"
							style={({ isActive }) => (isActive ? activeAdminNavStyle : { borderBottom: "", color: "" })}
						>
							Нормативна уредба
						</NavLink>
					</li>
					<li>
						<NavLink
							className="admin-nav-button"
							to="/admin/useful-links"
							style={({ isActive }) => (isActive ? activeAdminNavStyle : { borderBottom: "", color: "" })}
						>
							Полезни връзки
						</NavLink>
					</li>
					<li>
						<NavLink
							className="admin-nav-button"
							to="/admin/accredited-practices"
							style={({ isActive }) => (isActive ? activeAdminNavStyle : { borderBottom: "", color: "" })}
						>
							Акредитирани практики
						</NavLink>
					</li>
					<li>
						<NavLink
							className="admin-nav-button"
							to="/admin/users"
							style={({ isActive }) => (isActive ? activeAdminNavStyle : { borderBottom: "", color: "" })}
						>
							Потребители
						</NavLink>
					</li>
				</ul>
			</div>
			<div className="admin-main-section">
				<Outlet />
			</div>
		</div>
	);
}
