import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Alert } from "react-bootstrap";

import * as regulationsService from "../../../../services/regulationsService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";
import Loader from "../../../Shared/Loader/Loader.js";

import './EditRegulation.css'

import { UploadClient } from "@uploadcare/upload-client";
const client = new UploadClient({ publicKey: "7ea0355512c8959c84df" });

export default function EditRegulation() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [regulationInfo, setRegulationInfo] = useState({
		title: "",
		file_type: "",
		file_name: "",
		url: "",
	});
	const [errors, setErrors] = useState({
		title: "",
		file_type: "",
		url: "",
	});

	useEffect(() => {
		regulationsService
			.getSingleRegulation(id)
			.then((regulationData) => {
				setIsLoading(false);
				setRegulationInfo({
					title: regulationData.title,
					file_type: regulationData.file_type,
					url: regulationData.url,
				});
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}, []);

	const editRegulationCancelHandler = () => {
		navigate("/admin/legal-regulation");
	};

	const changeHandler = (e) => {
		setSelectedFile(e.target.files[0]);
		setRegulationInfo((state) => ({
			...state,
			file_name: e.target.files[0].name,
			file_type: e.target.files[0].type,
		}));
		setIsFilePicked(true);
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

    const editRegulationHandler = (e) => {
        e.preventDefault();

        setIsLoading(true);
        
        if (isFilePicked) {
             client
					.uploadFile(selectedFile)
					.then((file) => {
						setRegulationInfo((state) => ({
							...state,
							url: file.cdnUrl,
						}));
						regulationsService
							.editRegulation(id, {
								title: regulationInfo.title,
								file_type: regulationInfo.file_type,
								url: file.cdnUrl,
							})
							.then((data) => {
								setIsLoading(false);
								setSelectedFile(null);
                                setIsFilePicked(false);
                                notificationService.success("Успешно променен нормативен акт");
                                navigate("/admin/legal-regulation");
							})
							.catch((err) => {
								errorHandlingService.messageHandler(err);
								setIsLoading(false);
							});
					})
					.catch((err) => console.log(err));
        } else {
           regulationsService
				.editRegulation(id, {title: regulationInfo.title, file_type: regulationInfo.file_type, url: regulationInfo.url})
				.then((data) => {
					setIsLoading(false);
					setSelectedFile(null);
                    setIsFilePicked(false);
                    notificationService.success("Успешно променен нормативен акт");
                    navigate("/admin/legal-regulation");
				})
				.catch((err) => {
					errorHandlingService.messageHandler(err);
					setIsLoading(false);
				});
        }

    };
	
    return (
		<div className="edit-regulation-page-wrapper">
			<div className="edit-regulation-content-wrapper">
				<div className="edit-regulation-form-wrapper">
					{isLoading ? (
						<Loader />
					) : (
						<form className="edit-regulation-form" method="POST" onSubmit={editRegulationHandler}>
							<label htmlFor="title">Заглавие</label>
							<input
								onBlur={onBlurHandler}
								className="form-input"
								type="text"
								name="title"
								value={regulationInfo.title}
								onChange={(e) => {
									setErrors((state) => ({
										...state,
										title: "",
									}));
									setRegulationInfo((state) => ({
										...state,
										title: e.target.value,
									}));
								}}
							/>
							<Alert className="contact-form-alert" variant="danger" show={errors.title.length > 0}>
								{errors.title}
							</Alert>
							<span className="btn btn-primary btn-file">
								Избери нормативен акт...
								<input
									className="edit-regulation-file-upload-input"
									type="file"
									name="file"
									onChange={changeHandler}
								/>
							</span>
							{isFilePicked ? (
								<span>Избран файл: {regulationInfo.file_name}</span>
							) : (
								<span>Текущ файл: {<a href={regulationInfo.url}>{regulationInfo.url}</a>}</span>
							)}

							<div className="edit-regulation-button-wrapper">
								<input
									className="edit-regulation-button edit-regulation-edit-button"
									type="submit"
									value="Промени"
								/>
								<Link
									onClick={editRegulationCancelHandler}
									className="edit-regulation-button edit-regulation-deletes-button"
									to={"/admin/legal-regulation"}
								>
									Отказ
								</Link>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
}
