import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";

import * as newsService from "../../../../services/newsService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";
import Loader from "../../../Shared/Loader/Loader.js";

import "./EditNew.css";

import { UploadClient } from "@uploadcare/upload-client";
const client = new UploadClient({ publicKey: "7ea0355512c8959c84df" });

export default function EditNew() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({
		title: "",
		file_type: "",
		url: "",
	});
	const [newsInfo, setNewsInfo] = useState({
		title: "",
		file_type: "",
		file_name: "",
		url: "",
	});

	useEffect(() => {
		newsService
			.getSingleNew(id)
			.then((newData) => {
				setIsLoading(false);
				setNewsInfo({
					title: newData.title,
					file_type: newData.file_type,
					url: newData.url,
				});
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	}, []);

	const editNewCancelHandler = () => {
		navigate("/admin/news");
	};

	const editNewSubmitHandler = (e) => {
		e.preventDefault();

		setIsLoading(true);

		if (isFilePicked) {
			client
				.uploadFile(selectedFile)
				.then((file) => {
					setNewsInfo((state) => ({
						...state,
						url: file.cdnUrl,
					}));
					newsService
						.editNew(id, {
							title: newsInfo.title,
							file_type: newsInfo.file_type,
							url: file.cdnUrl,
						})
						.then((data) => {
							setIsLoading(false);
							setSelectedFile(null);
							setIsFilePicked(false);
							notificationService.success("Успешно променен нормативен акт");
							navigate("/admin/news");
						})
						.catch((err) => {
							errorHandlingService.messageHandler(err);
							setIsLoading(false);
						});
				})
				.catch((err) => console.log(err));
		} else {
			newsInfo
				.editNew(id, { title: newsInfo.title, file_type: newsInfo.file_type, url: newsInfo.url })
				.then((data) => {
					setIsLoading(false);
					setSelectedFile(null);
					setIsFilePicked(false);
					notificationService.success("Успешно променен нормативен акт");
					navigate("/admin/news");
				})
				.catch((err) => {
					errorHandlingService.messageHandler(err);
					setIsLoading(false);
				});
		}
	};

	const changeHandler = (e) => {
		setSelectedFile(e.target.files[0]);
		setNewsInfo((state) => ({
			...state,
			file_name: e.target.files[0].name,
			file_type: e.target.files[0].type,
		}));
		setIsFilePicked(true);
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	return (
		<div className="edit-new-page-wrapper">
			<div className="edit-new-content-wrapper">
				{isLoading ? (
					<Loader />
				) : (
					<form onSubmit={editNewSubmitHandler}>
						<label htmlFor="">Заглавие</label>
						<input
							className="edit-new-form-input"
							type="text"
							name="title"
							value={newsInfo.title}
							onBlur={onBlurHandler}
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									title: "",
								}));
								setNewsInfo((state) => ({
									...state,
									title: e.target.value,
								}));
							}}
						/>
						<Alert className="contact-form-alert" variant="danger" show={errors.title.length > 0}>
							{errors.title}
						</Alert>
						<span className="btn btn-primary btn-file">
							Избери файл с новина...
							<input
								className="edit-regulation-file-upload-input"
								type="file"
								name="file"
								onChange={changeHandler}
							/>
						</span>
						{isFilePicked ? (
							<span>Избран файл: {newsInfo.file_name}</span>
						) : (
							<span>Текущ файл: {<a href={newsInfo.url}>{newsInfo.url}</a>}</span>
						)}
						<div className="edit-new-button-wrapper">
							<input className="edit-new-button edit-new-edit-button" type="submit" value="Промени" />
							<Link
								onClick={editNewCancelHandler}
								className="edit-new-button edit-new-deletes-button"
								to={"/admin/news"}
							>
								Отказ
							</Link>
						</div>
					</form>
				)}
			</div>
		</div>
	);
}
