import { Tabs, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import * as newsService from "../../../services/newsService.js";
import * as errorHandlingService from "../../../services/errorHandlingSerivce.js";

import "./News.css";
import CreateNews from "./CreateNews/CreateNews.js";
import SingleNew from "./SingleNew/SingleNew.js";
import Loader from "../../Shared/Loader/Loader.js";
import ListPagination from "../../Shared/ListPagination/ListPagination.js";

export default function News() {
	const [key, setKey] = useState("news-list");
	const [news, setNews] = useState([]);
	const [newsCount, setNewsCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	function updateNewsState() {
		newsService
			.getNewsPaginated(0, 10)
			.then((newsData) => {
				setNews(newsData.news);
				setNewsCount(newsData.totalResults);
				setIsLoading(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}

	const handleCreate = () => {
		updateNewsState();
		setKey("news-list");
	};

	const handleDelete = () => {
		updateNewsState();
		setKey("news-list");
	};

	useEffect(() => {
		updateNewsState();
	}, []);

	const currentPage = (value) => {
		setIsLoading(true);
		newsService
			.getNewsPaginated((value - 1) * 10, 10)
			.then((newsData) => {
				setNews(newsData.news);
				setIsLoading(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	};

	const hasNews = (
		<table>
			<thead>
				<tr>
					<th>Заглавие</th>
					<th>Описание</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{news.map((neww) => (
					<SingleNew key={neww._id} neww={neww} handleDelete={handleDelete} />
				))}
			</tbody>
		</table>
	);

	const noNews = <h3 className="news-list-no-news">За момента няма новини</h3>;

	return (
		<div className="news-wrapper">
			<Tabs
				id="controlled-tab-news"
				activeKey={key}
				onSelect={(k) => {
					setKey(k);
				}}
				className="mb-3"
			>
				<Tab eventKey="news-list" title="Списък">
					<div className="news-tab-wrapper">
						<div className="admin-news-pagination">
							<ListPagination count={newsCount} currentPage={currentPage} />
						</div>
						{isLoading ? <Loader className="admin-news-loader" /> : news.length > 0 ? hasNews : noNews}
					</div>
				</Tab>
				<Tab eventKey="news-create" title="Нова новина">
					<CreateNews handleCreate={handleCreate} />
				</Tab>
			</Tabs>
		</div>
	);
}
