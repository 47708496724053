import baseUrl from "../constants.js";

export const create = async (title, file_type, url) => {
	let res = await fetch(`${baseUrl}/regulations`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			title: title,
			file_type: file_type,
			url: url,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getAllRegulations = async () => {
	let res = await fetch(`${baseUrl}/regulations`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getSingleRegulation = async (id) => {
	let res = await fetch(`${baseUrl}/regulations/${id}`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getRegulationsPaginated = async (startIndex, limit) => {
	const params = new URLSearchParams();
	params.append("startIndex", startIndex);
	params.append("limit", limit);
	let res = await fetch(`${baseUrl}/regulations/list?${params.toString()}`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const editRegulation = async (id, regulationData) => {
	let res = await fetch(`${baseUrl}/regulations/${id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(regulationData),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const deleteRegulation = async (id) => {
	let res = await fetch(`${baseUrl}/regulations/delete`, {
		method: "DELETE",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			id: id,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};
