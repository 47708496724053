import { useEffect, useState } from "react";

import * as practicesService from "../../services/practicesServices.js";
import * as errorHandlingService from "../../services/errorHandlingSerivce.js";
import Loader from "../Shared/Loader/Loader.js";

import "./Practices.css";

export default function Practices() {
	const [practices, setPractices] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		practicesService
			.getAllPractices()
			.then((data) => {
				setPractices(data);
				setIsLoading(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	}, []);

	const hasPractices = practices.map((practice, i) => (
		<div
			key={practice._id}
			className="practices-page-single-practice-wrapper"
			style={{ animationDelay: `${i / 2}s` }}
		>
			<h3 className="practices-page-single-practice-part">{practice.title}</h3>
			<p className="practices-page-single-practice-part">{`Адреси: \n${practice.address}`}</p>
			<p className="practices-page-single-practice-part">{`Контакти: \n${practice.contacts}`}</p>
		</div>
	));

	const noPractices = <h2 className="practices-page-no-practices">За момента няма акредитирани практики</h2>;

	return (
		<div className="practices-page-wrapper">
			<h1 className="practices-page-heading">Акредитирани практики</h1>
			<div className="practices-wrapper">{isLoading ? <Loader /> : practices.length > 0 ? hasPractices : noPractices}</div>
		</div>
	);
}
