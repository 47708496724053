import { useNavigate } from "react-router-dom";
import * as practicesService from "../../../../services/practicesServices.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

export default function SinglePractice({ practice, handleDelete }) {
    const navigate = useNavigate();

    const deletePracticeHandler = () => {
		practicesService
			.deletePractice(practice._id)
			.then(() => {
				notificationService.success("Успешно изтритa практика");
				handleDelete();
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	};

	const editPracticeHandler = () => {
		navigate(`/admin/accredited-practices/edit/${practice._id}`);
    };
    
	return (
		<tr className="practice-row">
			<td>{practice.title}</td>
			<td className="single-practice-address">{practice.address}</td>
			<td className="single-practice-contacts">{practice.contacts}</td>
			<td>
				<button onClick={editPracticeHandler} className="news-button news-edit" type="button">
					Промени
				</button>
				<button onClick={deletePracticeHandler} className="news-button news-delete" type="button">
					Изтрий
				</button>
			</td>
		</tr>
	);
}
