import baseUrl from "../constants.js";

export const create = async (logo, title, link, order) => {
	let res = await fetch(`${baseUrl}/links`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			logo: logo,
			title: title,
			link: link,
			order: order,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getAllLinks = async () => {
	let res = await fetch(`${baseUrl}/links`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const deleteLink = async (id) => {
	let res = await fetch(`${baseUrl}/links/delete`, {
		method: "DELETE",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			id: id,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getSingleLink = async (id) => {
	let res = await fetch(`${baseUrl}/links/${id}`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const editLink = async (id, linkData) => {
	let res = await fetch(`${baseUrl}/links/${id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(linkData),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getLinksPaginated = async (startIndex, limit) => {
	const params = new URLSearchParams();
	params.append("startIndex", startIndex);
	params.append("limit", limit);
	let res = await fetch(`${baseUrl}/links/list?${params.toString()}`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const reArrangeLinks = async (direction, oldOrder) => {
	let res = await fetch(`${baseUrl}/links/reorder`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			direction: direction,
			oldOrder: oldOrder,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const deleteReorder = async (order) => {
	let res = await fetch(`${baseUrl}/links/delreorder`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({order}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};
