import { useState } from "react";
import * as errorHandlingService from "../services/errorHandlingSerivce.js";

export default function useLocalStorage(key, initialValue) {
	const [state, setState] = useState(() => {
		try {
			let item = localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			errorHandlingService.messageHandler(error);
			return initialValue;
		}
	});

	const setItem = (value) => {
		try {
			localStorage.setItem(key, JSON.stringify(value));
			setState(value);
		} catch (error) {
			errorHandlingService.messageHandler(error);
		}
	};

	return [state, setItem];
}
