import { Alert } from "react-bootstrap";
import { useState } from "react";

import * as notificationService from "../../services/notificationService.js";
import * as doctorService from "../../services/doctorService.js";
import * as errorHandlingService from "../../services/errorHandlingSerivce.js";

import "./CheckObligationsPage.css";
import Loader from "../Shared/Loader/Loader.js";

export default function CheckObligationsPage() {
	const [uin, setUin] = useState();
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const checkUinHandler = (e) => {
		e.preventDefault();

		if (!uin) {
			return setError("Моля въведете УИН!");
		}

		if (isNaN(Number(uin)) || uin.length !== 10) {
			return setError("УИН трябва да бъде 10 цифри!");
		}

		setUin("");

		setIsLoading(true)

		doctorService
			.getMemberByUin(uin)
			.then((memberData) => {
				setIsLoading(false);
				if (!memberData.length) {
					return setError("Този УИН не беше намерен!");
				}
				if (memberData[0].obligations > 0) {
					return notificationService.provideData(
						`Вашето задължение е ${memberData[0].obligations.toFixed(2)}лв. !`
					);
				} else if (memberData[0].obligations === 0) {
					return notificationService.provideData("Вие нямате задължения!");
				} else {
					return notificationService.provideData(
						`Вие имате надплатени ${Math.abs(
							memberData[0].obligations
						)} лв. Таксата за следващ месец се причислява на всяко 1-во число от новият месец`
					);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				errorHandlingService.messageHandler(error);
			});
	};

	return (
		<>
			<div className="check-page-wrapper">
				<div className="check-page-content-wrapper">
					<h1 className="check-page-heading">Проверка на задължения</h1>
					{isLoading ? (
						<Loader />
					) : (
						<form onSubmit={checkUinHandler} className="check-page-form">
							<label htmlFor="uin">Моля въведете вашият УИН номер</label>
							<input
								type="text"
								name="uin"
								onChange={(e) => {
									setUin(e.target.value);
									setError("");
								}}
								value={uin}
							/>
							<Alert className="contact-form-alert" variant="danger" show={error.length > 0}>
								{error}
							</Alert>
							<button type="submit">Провери</button>
						</form>
					)}
				</div>
			</div>
		</>
	);
}
