import { useState } from "react";
import { Alert } from "react-bootstrap";

import * as newsService from "../../../../services/newsService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import "./CreateNews.css";

import { UploadClient } from "@uploadcare/upload-client";
const client = new UploadClient({ publicKey: "7ea0355512c8959c84df" });

export default function CreateNews({ handleCreate }) {
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [newsInfo, setNewsInfo] = useState({
		title: "",
		file_type: "",
		file_name: "",
		url: "",
	});
	const [errors, setErrors] = useState({
		title: "",
		file_type: "",
		url: "",
	});

	const createNewsHandler = (e) => {
		e.preventDefault();

		if (!newsInfo.title) {
			return notificationService.warning("Всички полета са задължителни!");
		}

		if (!selectedFile) {
			return notificationService.warning("Не сте избрали файл!");
		}

		setIsLoading(true);

		client
			.uploadFile(selectedFile)
			.then((file) => {
				setNewsInfo((state) => ({
					...state,
					url: file.cdnUrl,
				}));
				newsService
					.create(newsInfo.title, newsInfo.file_type, file.cdnUrl)
					.then((data) => {
						handleCreate();
						setIsLoading(false);
						setNewsInfo({
							title: "",
							file_type: "",
							file_name: "",
							url: "",
						});
						setSelectedFile(null);
						setIsFilePicked(false);
						notificationService.success("Успешно добавена новина!");
					})
					.catch((err) => {
						errorHandlingService.messageHandler(err);
						setIsLoading(false);
					});
			})
			.catch((err) => console.log(err));
	};

	const changeHandler = (e) => {
		setSelectedFile(e.target.files[0]);
		setNewsInfo((state) => ({
			...state,
			file_name: e.target.files[0].name,
			file_type: e.target.files[0].type,
		}));
		setIsFilePicked(true);
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	return (
		<div className="create-news-page-wrapper">
			<div className="create-news-content-wrapper">
				<div className="create-news-form-wrapper">
					<h3>Добави новина</h3>
					<form className="create-news-form" method="POST" onSubmit={createNewsHandler}>
						<label htmlFor="title">Заглавие</label>
						<input
							className="form-input"
							type="text"
							name="title"
							value={newsInfo.title}
							onChange={(e) => {
								setErrors((state) => ({
									...state,
									title: "",
								}));
								setNewsInfo((state) => ({
									...state,
									title: e.target.value,
								}));
							}}
							onBlur={onBlurHandler}
						/>
						<Alert className="contact-form-alert" variant="danger" show={errors.title.length > 0}>
							{errors.title}
						</Alert>
						<span className="btn btn-primary btn-file">
							Избери файл с новина...
							<input
								className="create-news-file-upload-input"
								type="file"
								name="file"
								onChange={changeHandler}
							/>
						</span>
						{isFilePicked ? <span>Избран файл: {newsInfo.file_name}</span> : ""}
						<input className="create-news-button" type="submit" value="Добави" />
					</form>
				</div>
			</div>
		</div>
	);
}
