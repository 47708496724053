import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

import "./App.css";
import ProtectedRoute from "./Guards/AuthGuard.js";
import NotFound from "./components/NotFoundPage/NotFoundPage.js";
import CheckObligationsPage from "./components/CheckObligationsPage/CheckObligationsPage.js";
import ApplyFormPage from "./components/ApplyFormPage/ApplyFormPage.js";
import Header from "./components/Core/Header/Header.js";
import GoToTop from "./components/GoToTop/GoToTop.js";
import Footer from "./components/Core/Footer/Footer.js";
import Users from "./components/Admin/Users/Users.js";
import EditDoctor from "./components/Admin/Doctors/EditDoctor/EditDoctor.js";
import Doctors from "./components/Admin/Doctors/Doctrors.js";
import EditNew from "./components/Admin/News/EditNew/EditNew.js";
import News from "./components/Admin/News/News.js";
import Admin from "./components/Admin/Admin.js";
import Login from "./components/User/Login/Login.js";
import Contacts from "./components/Contacts/Contacts.js";
import About from "./components/About/About.js";
import NewsDetailsPage from "./components/NewsDetailsPage/NewsDetailsPage.js";
import NewsPage from "./components/NewsPage/NewsPage.js";
import Home from "./components/Home/Home.js";
import Statute from "./components/Statute/Statute.js";
import AccreditedPractices from "./components/Admin/AccreditedPractices/AccreditedPractices.js";
import UsefulLinks from "./components/Admin/UsefulLinks/UsefulLinks.js";
import LegalRegulation from "./components/Admin/LegalRegulation/LegalRegulation.js";
import EditUsefulLink from "./components/Admin/UsefulLinks/EditUsefulLink/EditUsefulLink.js";
import UsefulLinksPage from "./components/UsefulLinksPage/UsefulLinksPage.js";
import EditPractice from "./components/Admin/AccreditedPractices/EditPractice/EditPractice.js";
import Practices from "./components/Practices/Practices.js";
import EditMember from "./components/Admin/Doctors/EditMember/EditMember.js";
import EditRegulation from "./components/Admin/LegalRegulation/EditRegulation/EditRegulation.js";
import LegalRegulationsPage from "./components/LegalRegulationsPage/LegalRegulationsPage.js";
import ThankYou from "./components/Contacts/ThankYou/ThankYou.js";

// const NewsDetailsPage = lazy(() => import("./components/NewsDetailsPage/NewsDetailsPage.js"));
// const EditDoctor = lazy(() => import("./components/Admin/Doctors/EditDoctor/EditDoctor.js"));
// const EditNew = lazy(() => import("./components/Admin/News/EditNew/EditNew.js"));
// const Doctors = lazy(() => import("./components/Admin/Doctors/Doctrors.js"));
// const NewsPage = lazy(() => import("./components/NewsPage/NewsPage.js"));
// const Loader = lazy(() => import("./components/Core/Loader/Loader.js"));
// const GoToTop = lazy(() => import("./components/GoToTop/GoToTop.js"));
// const Users = lazy(() => import("./components/Admin/Users/Users.js"));
// const Contacts = lazy(() => import("./components/Contacts/Contacts"));
// const Header = lazy(() => import("./components/Core/Header/Header"));
// const Footer = lazy(() => import("./components/Core/Footer/Footer"));
// const News = lazy(() => import("./components/Admin/News/News.js"));
// const Login = lazy(() => import("./components/User/Login/Login"));
// const Admin = lazy(() => import("./components/Admin/Admin"));
// const About = lazy(() => import("./components/About/About"));
// const Home = lazy(() => import("./components/Home/Home"));

function App() {
	const user = localStorage.getItem("user");

	return (
		<AuthProvider>
			<div className="app">
				<Header />

				{/* <Suspense  fallback={<Loader />}> */}
				<main className="site-content">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/news" element={<NewsPage />} />
						<Route path="/news/:id" element={<NewsDetailsPage />} />
						<Route path="/members" element={<About />} />
						<Route path="/apply" element={<ApplyFormPage />} />
						<Route path="/contacts" element={<Contacts />} />
						<Route path="/legislation" element={<LegalRegulationsPage />} />
						<Route path="/login" element={<Login />} />
						<Route element={<ProtectedRoute user={user} />}>
							<Route path="/admin" element={<Admin />}>
								<Route path="/admin/news" element={<News />} />
								<Route path="/admin/news/edit/:id" element={<EditNew />} />
								<Route path="/admin/docs" element={<Doctors />} />
								<Route path="/admin/docs/edit/:id" element={<EditDoctor />} />
								<Route path="/admin/members/edit/:id" element={<EditMember />} />
								<Route path="/admin/accredited-practices" element={<AccreditedPractices />} />
								<Route path="/admin/accredited-practices/edit/:id" element={<EditPractice />} />
								<Route path="/admin/useful-links" element={<UsefulLinks />} />
								<Route path="/admin/useful-links/edit/:id" element={<EditUsefulLink />} />
								<Route path="/admin/legal-regulation" element={<LegalRegulation />} />
								<Route path="/admin/legal-regulation/edit/:id" element={<EditRegulation />} />
								<Route path="/admin/users" element={<Users />} />
							</Route>
						</Route>
						<Route path="/statute" element={<Statute />} />
						<Route path="/practice" element={<Practices />} />
						<Route path="/links" element={<UsefulLinksPage />} />
						<Route path="/check" element={<CheckObligationsPage />} />
						<Route path="/thankyou" element={<ThankYou />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</main>
				{/* </Suspense> */}
				<GoToTop />
				<Footer />
			</div>
		</AuthProvider>
	);
}

export default App;
