import { Tabs, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";

import * as regulationsService from "../../../services/regulationsService.js";
import * as errorHandlingService from "../../../services/errorHandlingSerivce.js";

import Loader from "../../Shared/Loader/Loader.js";

import "./LegalRegulation.css";
import CreateRegulation from "./CreateRegulation/CreateRegulation.js";
import SingleRegulation from "./SingleRegulation/SingleRegulation.js";
import ListPagination from "../../Shared/ListPagination/ListPagination.js";

export default function LegalRegulation() {
	const [key, setKey] = useState("regulations-list");
	const [regulations, setRegulations] = useState([]);
	const [regulationsCount, setRegulationsCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	function updateRegulationsState() {
		setIsLoading(true);
		regulationsService
			.getRegulationsPaginated(0, 10)
			.then((regulationsData) => {
				setRegulations(regulationsData.regulations);
				setRegulationsCount(regulationsData.totalResults)
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				errorHandlingService.messageHandler(err);
			});
	}

	useEffect(() => {
		updateRegulationsState();
	}, []);

	const handleDelete = () => {
		updateRegulationsState();
		setKey("regulations-list");
	};

	const handleCreate = () => {
		updateRegulationsState();
		setKey("regulations-list");
	};

	const currentPage = (value) => {
		setIsLoading(true);
		regulationsService
			.getRegulationsPaginated((value - 1) * 10, 10)
			.then((regulationsData) => {
				setRegulations(regulationsData.news);
				setIsLoading(false);
			})
			.catch((err) => {
				errorHandlingService.messageHandler(err);
			});
	};

	const hasRegulations = (
		<table className="table-sticky-header">
			<thead>
				<tr>
					<th></th>
					<th>Заглавие</th>
					<th>Файл</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{regulations.map((regulation) => (
					<SingleRegulation key={regulation._id} regulation={regulation} handleDelete={handleDelete} />
				))}
			</tbody>
		</table>
	);

	const noRegulations = <h3 className="regulations-list-no-regulations">За момента няма нормативни актове</h3>;

	return (
		<div className="regulations-wrapper">
			<Tabs
				id="controlled-tab-regulations"
				activeKey={key}
				onSelect={(k) => {
					setKey(k);
				}}
				className="mb-3"
			>
				<Tab eventKey="regulations-list" title="Нормативна уредба">
					<div className="regulations-tab">
						<div className="admin-news-pagination">
							<ListPagination count={regulationsCount} currentPage={currentPage} />
						</div>
						{isLoading ? <Loader /> : regulations.length > 0 ? hasRegulations : noRegulations}
					</div>
				</Tab>
				<Tab eventKey="regulations-create" title="Нов нормативен акт">
					<CreateRegulation handleCreate={handleCreate} />
				</Tab>
			</Tabs>
		</div>
	);
}
