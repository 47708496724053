import { useState } from "react";

import { Alert } from "react-bootstrap";

import * as regulationsService from "../../../../services/regulationsService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";
import Loader from "../../../Shared/Loader/Loader.js";

import "./CreateRegulation.css";

import { UploadClient } from "@uploadcare/upload-client";
const client = new UploadClient({ publicKey: "7ea0355512c8959c84df" });

export default function CreateRegulation({ handleCreate }) {
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [regulationInfo, setRegulationInfo] = useState({
		title: "",
		file_type: "",
		file_name: "",
		url: "",
	});
	const [errors, setErrors] = useState({
		title: "",
		file_type: "",
		url: "",
	});

	const createRegulationHandler = (e) => {
		e.preventDefault();

		if (!selectedFile) {
			return notificationService.warning("Не сте избрали файл!");
		}

		setIsLoading(true);

		client
			.uploadFile(selectedFile)
			.then((file) => {
				setRegulationInfo((state) => ({
					...state,
					url: file.cdnUrl,
                }));
				regulationsService
					.create(regulationInfo.title, regulationInfo.file_type, file.cdnUrl)
					.then((data) => {
						handleCreate();
						setIsLoading(false);
						setRegulationInfo({
							title: "",
							file_type: "",
							file_name: "",
							url: "",
						});
						setSelectedFile(null);
						setIsFilePicked(false);
						notificationService.success("Успешно добавен нормативен акт!");
					})
					.catch((err) => {
						errorHandlingService.messageHandler(err);
						setIsLoading(false);
					});
			})
			.catch((err) => console.log(err));
	};

	const changeHandler = (e) => {
		setSelectedFile(e.target.files[0]);
		setRegulationInfo((state) => ({
			...state,
			file_name: e.target.files[0].name,
			file_type: e.target.files[0].type,
		}));
		setIsFilePicked(true);
	};

	const onBlurHandler = (e) => {
		let name = e.target.name;
		if (e.target.value.length === 0) {
			setErrors((state) => ({
				...state,
				[name]: "Това поле е задължително",
			}));
		}
	};

	return (
		<div className="create-regulation-page-wrapper">
			<div className="create-regulation-content-wrapper">
				<div className="create-regulation-form-wrapper">
					<h3>Добави нормативен акт</h3>
					{isLoading ? (
						<Loader />
					) : (
						<form className="create-regulation-form" method="POST" onSubmit={createRegulationHandler}>
							<label htmlFor="title">Заглавие</label>
							<input
								onBlur={onBlurHandler}
								className="form-input"
								type="text"
								name="title"
								value={regulationInfo.title}
								onChange={(e) => {
									setErrors((state) => ({
										...state,
										title: "",
									}));
									setRegulationInfo((state) => ({
										...state,
										title: e.target.value,
									}));
								}}
							/>
							<Alert className="contact-form-alert" variant="danger" show={errors.title.length > 0}>
								{errors.title}
							</Alert>
							<span className="btn btn-primary btn-file">
								Избери нормативен акт...
								<input
									className="create-regulation-file-upload-input"
									type="file"
									name="file"
									onChange={changeHandler}
								/>
							</span>
							{isFilePicked ? <span>Избран файл: {regulationInfo.file_name}</span> : ""}

							<input className="create-regulation-button" type="submit" value="Добави" />
						</form>
					)}
				</div>
			</div>
		</div>
	);
}
