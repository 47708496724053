import * as notificationService from "../../../services/notificationService.js";

import "./DoctorCard.css";

export default function DoctorCard({ doctor, index }) {
	const doctorDetailsShow = () => {
		notificationService.dataWithImg({
			firstName: doctor.firstName,
			lastName: doctor.lastName,
			description: doctor.description,
			img: doctor.imgUrl,
		});
	};

	return (
		<div className="card-wrapper" style={{ animationDelay: `${index / 2}s` }}>
			<div className="visible-doctor-card">
				<img src={doctor.imgUrl} alt="" />
				<h1>
					Др. {doctor.firstName} {doctor.lastName}
				</h1>
				<p>{doctor.specialty}</p>
			</div>
			<div className="hidden-doctor-card">
				<h1>Запознайте се с д-р {doctor.lastName}</h1>
				<p className="hidden-doctor-card-p">{`${doctor.description.substring(0, 100)}...`}</p>
				<button onClick={doctorDetailsShow} className="doctor-card-button">
					Научи повече
				</button>
			</div>
		</div>
	);
}
