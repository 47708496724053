import { useEffect, useState } from "react";

import * as usefulLinksService from "../../services/usefulLinksService.js";
import * as errorHandlingService from "../../services/errorHandlingSerivce.js";
import Loader from "../Shared/Loader/Loader.js";

import "./UsefulLinksPage.css";
import { useNavigate } from "react-router-dom";

export default function UsefulLinksPage() {
	const [links, setLinks] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		usefulLinksService
			.getAllLinks()
			.then((linksData) => {
				setLinks(linksData);
				setIsLoading(false);
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	}, []);

	
	const hasLinks = links.map((link, i) => (
		<div key={link._id} className="usefulLinks-page-single-new-wrapper" style={{ animationDelay: `${i / 2}s` }}>
			<img alt="" src={link.logo} className="usefulLinks-page-single-link-logo"></img>
			<span>{link.title}</span>
			<a href={link.link} target="_blank" rel="noopener noreferrer" className="usefulLinks-page-single-link-link">
				{link.link}
			</a>
		</div>
	));

	const noLinks = <h2 className="news-page-no-news">За момента няма полезни връзки</h2>;

	return (
		<div className="usefulLinks-page-wrapper">
			<h1 className="usefulLinks-page-heading">Полезни връзки</h1>
			{isLoading ? <Loader /> : links.length > 0 ? hasLinks : noLinks}
		</div>
	);
}
