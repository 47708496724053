import { createContext, useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

// export interface IAuth {
// 	user: any;
// 	login(data: any): any;
// 	logout(): void;
// 	isAuthenticated: boolean;
// }

// type ProvideProps = {
// 	children: ReactNode
// }

const initialAuthState = {
	_id: "",
	email: "",
};
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useLocalStorage("user", initialAuthState);

	const login = (authData) => {
		setUser(authData);
		document.cookie = "auth-cookie=" + authData.token;
	};

	const logout = () => {
		setUser(initialAuthState);
	};

	return (
		<AuthContext.Provider value={{ user, login, logout, isAuthenticated: Boolean(user.email) }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => {
	const authState = useContext(AuthContext);

	return authState;
};
