import Hero from "./Hero/Hero";
import News from "./News/News";

import "./Home.css";

export default function Home() {
	return (
		<main className="main-content-wrapper">
			<section>
				<Hero />
			</section>
			<section>
				<News />
			</section>
		</main>
	);
}
