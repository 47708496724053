import { useNavigate } from "react-router-dom";
import * as newsService from "../../../../services/newsService.js";
import * as notificationService from "../../../../services/notificationService.js";
import * as errorHandlingService from "../../../../services/errorHandlingSerivce.js";

import "./SingleNew.css";

export default function SingleNew({ neww, handleDelete }) {
	const navigate = useNavigate();

	const deleteNewsHandler = () => {
		newsService
			.deleteNew(neww._id)
			.then(() => {
				notificationService.success("Успешно изтритa новина");
				handleDelete();
			})
			.catch((err) => errorHandlingService.messageHandler(err));
	};

	const editNewsHandler = () => {
		navigate(`/admin/news/edit/${neww._id}`);
	};

	return (
		<tr className="doctor-row">
			<td className="regulation-logo">
				
				{neww.file_type ? neww.file_type.includes("word") ? (
					<img alt="" src="/images/word.jpeg" />
				) : (
					<img alt="" src="/images/pdf.jpg" />
				): ""}
			</td>
			<td>{neww.title.length > 300 ? neww.title.substring(0, 300) + " ..." : neww.title}</td>
			<td>
				<a href={neww.url} target="_blank" rel="noopener noreferrer">
					{neww.url}
				</a>
			</td>
			<td>
				<button onClick={editNewsHandler} className="news-button news-edit" type="button">
					Промени
				</button>
				<button onClick={deleteNewsHandler} className="news-button news-delete" type="button">
					Изтрий
				</button>
			</td>
		</tr>
	);
}
