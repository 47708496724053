import baseUrl from "../constants.js";

export const create = async (firstName, lastName, specialty, description, imgUrl) => {
	let res = await fetch(`${baseUrl}/doctors`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			firstName: firstName,
			lastName: lastName,
			specialty: specialty,
			description: description,
			imgUrl: imgUrl,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const uploadPhoto = async (data) => {
	const res = await fetch("http://localhost:3030/api/doctors/upload", {
		method: "POST",
		body: data,
	});
	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const createMember = async ({ firstName, secondName, lastName, phone_number, email, uin, practice }) => {
	let res = await fetch(`${baseUrl}/doctors/members`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			firstName: firstName,
			secondName: secondName,
			lastName: lastName,
			phone_number: phone_number,
			email: email,
			uin: uin,
			practice: practice,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getAllDoctors = async () => {
	let res = await fetch(`${baseUrl}/doctors`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getSingleDoctor = async (id) => {
	let res = await fetch(`${baseUrl}/doctors/${id}`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getSingleMember = async (id) => {
	let res = await fetch(`${baseUrl}/doctors/members/${id}`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getAllMembers = async () => {
	let res = await fetch(`${baseUrl}/doctors/members`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getMemberByUin = async (uin) => {
	let res = await fetch(`${baseUrl}/doctors/members/find`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			uin: uin,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const editDoctor = async (id, doctorData) => {
	let res = await fetch(`${baseUrl}/doctors/${id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(doctorData),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const editMember = async (id, memberData) => {
	let res = await fetch(`${baseUrl}/doctors/members/${id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(memberData),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const updateMemberObligations = async (id, amount) => {
	let res = await fetch(`${baseUrl}/doctors/members/obligations/${id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(amount),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const deleteDoctor = async (id) => {
	let res = await fetch(`${baseUrl}/doctors/delete`, {
		method: "DELETE",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			id: id,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const deleteMember = async (id) => {
	let res = await fetch(`${baseUrl}/doctors/members/delete`, {
		method: "DELETE",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			id: id,
		}),
	});

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};

export const getMembersPaginated = async (startIndex, limit) => {
	const params = new URLSearchParams();
	params.append("startIndex", startIndex);
	params.append("limit", limit);
	let res = await fetch(`${baseUrl}/doctors/members/list?${params.toString()}`);

	let jsonRes = await res.json();
	if (res.ok) {
		return jsonRes;
	} else {
		throw jsonRes.message;
	}
};
