import { useNavigate } from "react-router-dom";
import "./SingleNew.css";

export default function SingleNew({ neww, index }) {
	const navigate = useNavigate();

	const singleNewClickHandler = (id) => {
		if (neww.description) {
			navigate(`/news/${id}`);
		} else {
			openInNewTab(neww.url);
		}
	};

	function convertDate(date) {
		let newDate = date.split("T")[0];
		return newDate;
	}

	const openInNewTab = (url) => {
		window.open(url, "_blank", "noopener,noreferrer");
	};

	return (
		<div className="home-single-new-wrapper" style={{ animationDelay: `${index / 2}s` }}>
			{neww.title.length > 50 ? <h3>{`${neww.title.substring(0, 50)}...`}</h3> : <h3>{neww.title}</h3>}
			{neww.description ? (
				<p>{`${neww.description.substring(0, 200)}...`}</p>
			) : (
				<div className="news-page-single-regulation-logo-title-wrapper" onClick={() => openInNewTab(neww.url)}>
					{neww.file_type ? (
						neww.file_type.includes("word") ? (
							<img className="regulation-img" alt="" src="/images/word.jpeg" />
						) : (
							<img className="regulation-img" alt="" src="/images/pdf.jpg" />
						)
					) : (
						""
					)}
				</div>
			)}
			<button onClick={() => singleNewClickHandler(neww._id)} type="button" className="home-single-new-button">
				Виж повече
			</button>
			<div className="home-single-new-date">{convertDate(neww.created_at)}</div>
		</div>
	);
}
